/*jshint esversion: 6*/

export default function llati(s){
  var trad={
    "Á":"A",
    "À":"A",
    "á":"a",
    "à":"a",

    "É":"E",
    "È":"E",
    "é":"e",
    "è":"e",

    "Í":"I",
    "Ï":"I",
    "í":"i",
    "ï":"i",

    "Ó":"O",
    "Ò":"O",
    "ó":"o",
    "ò":"o",

    "Ú":"U",
    "Ü":"U",
    "ú":"u",
    "ü":"u",


    "Ç": "C",
    "ç": "c",

    "Ñ":"N",
    "ñ":"n"
  };

  return s.replace(
    /[^A-Za-z0-9\[\] ]/g,
    function(a){
      return trad[a]||a;
    }
  );
}
