/*jshint esversion: 6*/

export default function correu(){//0.0.13 / 0.0.14
  if($(".correu").length){
    $(".correu").each(function(i){
      var
        el=$(this),
        email=el.text().split(' ')[0]+"@idescat.cat"
      ;

      el.html( '<a href="mailto:'+email+'">'+email+"</a>" );
    });
  }
}//correu()
