/*jshint esversion: 6*/

export default function lineal(){
  $("table.ApartNum.xs").each(function(i){
    /* Cal tenir en compte que no funcionarà bé en elements ocults: cal buscar un parent no ocult que ens doni idea d'amplada efectiva de la taula (veure solució adoptada a emex.js)
    https://api.jquery.com/width/ : The value reported by .width() is not guaranteed to be accurate when the element or its parent is hidden. To get an accurate value, ensure the element is visible before using .width().
    */
    if(	//Si taula no cap (1) > (2)
      //(1) si volem no dependència de de box-sizing i d'existència de paddding i border, utilitzar $(this).css("width") treient "px"
      //(2) viewport
      $(this).width()+20 > $(window).width() //0.0.12 10px+10px de marge per si taula no ocupa 100% pq està dins d'apartat
    ){
      $(this).addClass("lineal"); //no ha d'afegir xs sinó una d'addicional
      if($("#cava").length){
        $(this).parents(".taula").addClass("lineal");
      }
    }
  });
}
