/*jshint esversion: 6*/

import getNextAriaControlsId from "./getnextariacontrolsid.js";
import nooutline from "./nooutline.js";

//Invocació (és multiinvocable: en una pàgina pot haver més d'un botó)
//id requerit. Ha de ser id únic i apuntar a element que cal ocultar:
//IDESCAT.onoff({id: "Espanya", label: "Espanya"});
//En la configuració actual el botó s'insereix just abans d'aquest element.
//Però com és un objecte podria flexibilitxar-se en el futur en cas de ser necessari amb un segon id (where) on incrustar el botó {id: "id", where: ""}
export default function interruptor(o){
	if(typeof o!=="object" || !o.target){
		return;
	}
	if (!o.id) {
		o.id=getNextAriaControlsId();
	}

	const html = 
		'<div class="onoff">'+
			'<input '+(o.isChecked ? 'checked="checked"' : '')+' id="'+o.id+'" type="checkbox" class="invisible" tabindex="0" name="'+o.id+'" value="0" aria-controls="'+o.id+'"/>'+
			'<label for="'+o.id+'"'+(o.title ? ' title="'+o.title+'"' : '')+'>'+
				(o.dreta ? '' : '<span class="interruptor"></span>')+
				'<span role="button" tabindex="-1" aria-pressed="'+(o.isChecked ? o.isChecked : 'false')+'">'+o.label+'</span>'+
				(o.dreta ? '<span class="interruptor"></span>' : '')+
			'</label>'+	
		'</div>';
	
	if (o.append) {
		o.target.append(html);
	} else {
		$(html).insertBefore(o.target);
	}

	$('.onoff input#'+o.id).on('focusin', function() {
		$(this).siblings('label').find('span[role=button]').addClass('outline');
	}).on('focusout', function() {
		$(this).siblings('label').find('span[role=button]').removeClass('outline');
	});

	$('.onoff input#'+o.id+'+label span.interruptor').mouseover(function(){
		$(this).siblings('span').addClass('nooutline').removeClass('outline');
   }).mouseleave(function(){
		$(this).siblings('span').removeClass('nooutline').removeClass('outline');
   });

	nooutline($('.onoff input#'+o.id+'+label span[role=button]'));

}