/*jshint esversion: 6*/

import getNextAriaControlsId from "./getnextariacontrolsid.js";


/**********************************************************************/
/* Seguint el consorci del web, les taules segueixen els criteris	 */
/* d'accessibilitat i només hi ha problemes al tfoot per al validador */
/* axe. Això ens permet ignorar el tfoot ara mateix ja que el		 */
/* Ministerio accepta l'accessibilitat que oferim actualment i, a més */
/* a més, en un futur proper, el tfoot hauria de desaparèixer.		*/
/**********************************************************************/

export default function enriqueixTaules() {
	function getId(object) {
		let id;
	
		if (object.attr('id')) {
			id = object.attr('id');
		} else {
			id = getNextAriaControlsId();
			object.attr('id', id);
		}
	
		return id;
	}	

	function columnes(trs) {
		let
			headersc=[],// capçaleres columna
			thzid=null
		;
	
		// Assignem ids a la capcelera i headers a la subcapcelera
		trs.each(function() {
			let currentCol = 0;
			$(this).find('th').each(function(k, thObject) {
				const th = $(thObject);
				// La cel·la etieti de la canonada superior esquerra
				if (th.hasClass('etieti') && th.text() != '') {
					thzid = getId(th);
				}
				if (th.attr('scope') === 'col' && th.text() != '') {
					
					// Trobem una cel·la amb contingut i que ocupa una sola columna.
					// Si headersc és més gran que 'k' (k és l'index del th actual),
					// significa que estem iterant almenys la 2a fila del thead.
					// Altrament és la 1a fila del thead i haurem d'agefir un array
					// amb el thcid (identificador de la columa).
					
					const thcid = getId(th);					
					if (headersc.length > k) {
						// 2a o més filera del thead
						headersc[currentCol].push(thcid);
					} else {
						// 1a filera del thead
						headersc.push([thcid]);
					}
					++currentCol;

				} else {

					// Contemplem diversos casos:
					// 1) Si th.attr('scope')==='colgroup' significa que la cel·la 
					//    ocupa més d'una columna (ex: sexe -> home dona total). També tindrà
					//	   l'atribut colspan. Haurem de guardar tantes vegades l'id thcid
					//    com el valor del colspan.
					// 2) Una casella en blanc sense dades:
					// 	<th></th> || <th scope="col"></th> || <th colspan="2"></th>

					const 
						thcid = th.attr('scope') === 'colgroup' ? getId(th) : '',
						colspan = th.attr('colspan') ? parseInt(th.attr('colspan')) : 1,
						limitCol = currentCol + colspan
					;
					while(currentCol < limitCol) {
						headersc.push([thcid]);
						++currentCol;
					}

				}
			});
		});
	
		// Exemple d'estructura:
		// https://www.idescat.cat/nadons/
		//
		// |   |    |   |  Ranquing  |
		// |___|____|___|____________|
		// |Pos|Sexe|Nom|Freqüència|‰|
		//
		// Exemple Resultat:
		// { 
		//   headersc: [[,2],[,3],[,4],[1,5][1,6]],
		//	  thzid: null
		// }
		// 

		return {
			"headersc": headersc,
			"thzid": thzid //id de casella etieti si no està buida
		};
	}
	
	function files(body, head) {
		
      function tbody(body, head) {
			let 
            headersr = [], // capçaleres fila
				titolid = ''
         ;

			body.find('tr').each(function(r, rowObject) {
				// currentCol és una variable que ens permet controlar quina columna de la taula
				// estem tractant actualment. No ens serveix l'iterador de l'each perquè tenim
				// th's que iteren de 0 a X (els que hi hagi) i tenim td's que itera de 
				// 0 a Y (els que hi hagi) en lloc de fer-ho d'X a Y+X.
				let 
               currentCol = 0,
					row = $(rowObject)
            ;

				row.find('th').each(function(k, thObject) {
					let th = $(thObject);

					if ($(row).hasClass('titol')) {
						titolid = getId(th);
					}
					if (th.attr('scope')==='row') {
						if (k === 0) {
							// afegim el thzid, l'id de la cantonada superior esquerra
							// titolid l'afegim encara que estigui buit.
							headersr.push([head["thzid"], titolid]);
						}
						// Agafem l'identificador del th de la fila, serà el mateix per a tots els td
						const thrid = getId(th);
						// headersr.length > r per assegurar-nos que ha passat per l'anterior push 
						// (2 línies amunt) i no estem a la 1a posició
						// titolid != thrid per evitar tenir un id repetit. AIxò passa en les taules
						// que tenen columnes simples i files complexes. Entra a l'if $(row).hasClass('titol')
						// i llavors el següent getId obté el mateix id. Així ho evitem.
						if (headersr.length > r && titolid != thrid) {
							headersr[r].push(thrid);
						}
					} else {
						// Casos il·legals. Fem un push buit per mantenir l'estructura de
						// les dades en relació a la taula
						headersr.push([]);
					}
					++currentCol;
				});
				// La cel·la amb dades
				row.find('td').each(function(){
					let td = $(this);
					if (head && !td.attr('headers')) {
						// Fem el filter per eliminar tots els elements buits. Finalment el join per tenir-ho tot en un array.
						const 
							hc = head.headersc.length > currentCol ? head.headersc[currentCol].filter(x => x).join(' ') : '',
							hr = headersr.length > r ? headersr[r].filter(x => x).join(' ') : ''
						;
						td.attr('headers', [hc, hr].filter(x => x).join(' '));
						++currentCol;
					}
				});
			});
		}
	
		//.filter(':has(>.titol:first-child)')
		body.each(function() {
			tbody($(this), head);
		});
	}
	
	
	const taules=$("table.ApartNum").filter(':has(colgroup), :has(tbody>.titol:first-child)');
	//Tractem segons si complexitat és per files o per columnes
	taules.each(function(){
		files(
            // :has(>.titol:first-child) -> Cas de taules amb files complexes però columnes simples
            // :not(.Extensio) -> Cas de taules extensibles però obviem el body que té les fletxes
			$(this).find('tbody').filter(':has(>.titol:first-child), :not(.Extensio)'), 
			columnes(
				$(this).find("thead > tr") // tractem només les files del thead
			)
		);
	});
}