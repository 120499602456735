/*jshint esversion: 6*/

import alertARIA from "./alertaria.js";
import getNextAriaControlsId from "./getnextariacontrolsid.js";
import {addFunc, isFunc} from "./addisfunc.js";
import trvincle from "./trvincle.js";
import enriqueixTaules from "./enriqueixTaules.js";

//No es contempla l'escenari que tinguem més d'una taula del tipus 'Extensible' a la mateixa pàgina
export default function extensible(){
	alertARIA();//inicialitzem l'element d'avisos per l'accessibilitat
	if($(".Extensible").length){
		var
			pattern=/[\\?&]posicio=([^&#]*)/,
			vseg={title:"",alt:"",src:""},
			vant={backgroundColor:""},
			callbackfn=null,
			actualitzarClassDarrer=function(element){
				$("tbody.darrer").removeClass("darrer");
				element.addClass("darrer");
			},
			actualitzarMissatge=function(num){
				var msg=IDESCAT.msg.numResultats.replace("{NUM}", num);
				alertARIA(msg);
			},
			afegirAccessibilitat=function(tbodyExtensio, tbody){
				var a = $(this),
					//tbody = a.closest("tbody"),
					ariaControlsId = tbody.attr("id")
				;
				// $("tbody.Extensio ul li.ant a").each(function(){
				tbodyExtensio.find("ul li.ant a")
					.attr({
						"role":"button",
						"aria-controls":ariaControlsId,
						"aria-expanded":tbody.hasClass("ocult") ? false : true
					})
				;

				$("tbody.darrer").next("tbody").find("ul li.seg a")
					.attr({
						"role":"button",
						"aria-controls":tbody.attr("id"),
						"aria-expanded":tbody.hasClass("ocult") ? false : true
					})
				;
				// tbody.closest("tbody.Extensio.ocult").find("ul li.seg a").attr("aria-controls",tbody.attr("id"));
			},
			segrestaNavegacio=function(callback){
				if(typeof(callback)!=="undefined"){callbackfn=callback;}

				$("tbody.Extensio").each(function(){
					var ariaControlsId = getNextAriaControlsId(),
            			tbodyExtensio = $(this),
            			tbody = tbodyExtensio.prev("tbody"),
						tbodyDarrer = $("tbody.darrer"),
            			numRows = 0
          			;
					tbodyExtensio.find("ul li.seg a")
						.attr({
							"role":"button",
							"aria-expanded":"false"
						})
					;

					//Modifico els botons
					tbodyExtensio.find("ul").each(function(){
						var ul = $(this);
						ul.children("li").children("img").each(function(){
							var img = $(this);
							if(img.parent("li").hasClass("ant")){
								img.attr("alt","");
							}else{
								img.attr("alt","");
							}
						});


						/***** GESTIO DEL BOTO MENYS RESULTATS *******/
						ul.find("li.ant a").each(function(){
							var a = $(this);
							a.unbind("click");
							a.click(function(){
								redueixResultats($(this));
								return false;
							});
						});

						//***** GESTIO DEL BOTO MES RESULTATS *******/
						ul.find("li.seg a").each(function(){
							var a = $(this);
							a.unbind("click");
							a.click(function(){
								// AMG20220920 això provoca que es perdi el segrest
								//$(this).unbind("click"); //evito que faci dos clicks molt ràpids
								recuperaResultats($(this));
								return false;
							});
						});
					});

					if(!tbody.data("rows")){
						numRows = tbodyDarrer.length ?  (tbodyDarrer.data("rows")+tbody.find("tr").length) : tbody.find("tr").length;
						tbody
							.attr({
								"id":ariaControlsId,
								"data-rows":numRows,
							})
						;
						afegirAccessibilitat(tbodyExtensio, tbody);
						actualitzarClassDarrer(tbody);
						if(tbodyDarrer.length){
							actualitzarMissatge(numRows);
						}
						enriqueixTaules();
					}
				});
			},
			// Evito que es pugin sol·lapar dos clicks dels botons de més/menys resultats
			desactivaNavegacio=function(cas){
				var
					antJS = $("tbody.Extensio:not(.ocult) li.ant a"),
					segJS = $("tbody.Extensio:not(.ocult) li.seg a")
				;
				antJS.unbind("click").click(function(){return false;});
				segJS.unbind("click").click(function(){return false;});
				vseg=segJS.find("img");
				vseg.title=vseg.attr("title");
				vseg.alt=vseg.attr("alt");
				vseg.src=vseg.attr("src");
				vant.backgroundColor=antJS.css("background-color");
				//XMB20180403 Elimino: antJS.css("cursor", "default");
				//antJS.css("background-color","#eff4f8");
				/*if(cas==="ajax"){ //imatge carregant//NOTE: Eliminat no cal
					$("tbody.Extensio:not(.ocult) a.segJS img").attr({title:IDESCAT.msg.carregant,
						alt:IDESCAT.msg.carregant,src:"/images/carrega.gif"});
				}*/
			},
			/*Simplement amaga tbodies
			@param object o Vincle "-" clicat.*/
			redueixResultats=function(a){
				var
					extensio=a.closest(".Extensio"), //actual barra Extensio
					tbody=extensio.prev("tbody"), //últim tbody XMB20180403 Afegeixo "tbody" pq ha canviat <table> a HTML5: ja no va abans de <tbody>
					tr=tbody.children("tr"),
					extensioAnt=tbody.prevAll(".ocult").first(), //penultima barra Extensio. XMB20180403 Canvio prev() per prevAll(".ocult").first() pq ha canviat <table> a HTML5: ja no va abans de <tbody>
					tbodyAnt=extensioAnt.prev("tbody")
				;

				//a.css("background-color", "#eff4f8");
				//ocultar els tr's un per un
				for(var i=tr.length; i>=0 ;i--){$(tr[i]).fadeOut(150);}

				//queue=true -> les instruccions dins animate s'execuraràn despres d'haber fet tots els fadeOut,
				tbody.animate({queue: true} , function(){
					//ocultar actual barra .Extensio
					extensio.addClass("ocult");
					//ocultar tbody
					$(tbody).addClass("ocult");
					//mostrar la penultima barra Extensio
					extensioAnt.removeClass("ocult");
					a.css("background-color", "");
          // afegirAccessibilitat();
				});

				if(isFunc("desplegable")){
					a.closest(".Desplegable").find("tr.Desplegat").removeClass("Desplegat");
				}
				a.attr("aria-expanded", "false");
				actualitzarClassDarrer(tbodyAnt.first());
				actualitzarMissatge(tbodyAnt.data("rows"));
				extensioAnt.find("ul li.seg a").attr("aria-expanded", "false");
				extensio.find("ul li.ant a").attr("aria-expanded", "false");
			},
			recuperaResultats=function(o){
				var
					/* @param <object> v vincle que s'ha clicat */
					activaNavegacio=function(cas){
						//NOTE: Eliminat no cal
						// var antJS=$("tbody.Extensio:not(.ocult) a.antJS");
						// antJS.click(function(){
						// 	redueixResultats($(this));
						// 	return false;
						// });
						//NOTE: Eliminat no cal
						// $("tbody.Extensio:not(.ocult) a.segJS").click(function(){
						// 	recuperaResultats($(this));
						// 	return false;
						// });

						//antJS.css("background-color",vant.backgroundColor);
						//NOTE: Eliminat no cal
						// if(cas==="ajax"){
						// 	$("tbody.Extensio:not(.ocult) a.segJS img").attr({title:vseg.title,alt:vseg.alt,src:vseg.src});
						// }
						//XMB20180403 Elimino: antJS.css("cursor", "auto");
					},
					tbodiesOcults=$(o).parents(".Extensible").children("tbody:not(.Extensio).ocult"),
					tbodyAnterior=$(tbodiesOcults[0])
				;
				if(tbodiesOcults.length){
					//amago els botons que s'han clicat
					$(o).parents("tbody").addClass("ocult");
					//Mostro el primer tbody amagat que conté les dades i els seus botons
					tbodyAnterior.removeClass("ocult");
					tbodyAnterior.children("tr:not(.Desplegament)").show();
					tbodyAnterior.next("tbody").removeClass("ocult");
					o.attr("aria-expanded", "true");
					tbodyAnterior.next("tbody").find("ul li.ant a").attr("aria-expanded", "true");
					actualitzarClassDarrer(tbodyAnterior);
					actualitzarMissatge(tbodyAnterior.data("rows"));
				}else{
					/* Nota: Això: $(o).attr("href"), m'hauria de retornar simplement el que té l'atribut,
					però l'ie8 enganxa per davant el domini en el qual s'està executant la pàgina. Per tant,
					si jo esperava tenir p.ex. "?a=1&amp;p=2", l'explorer em retorna "http://domini_actual/index.php?a=1&amp;p=2".
					Per evitar-ho, o es fan sempre adreces absolutes o faig l'split a partir de l'interrogant.
					*/
					desactivaNavegacio("ajax"); //Mentres es demanen més resultats no es clicable
					$.get($(o).attr("href")+"&m=a",function(data){
						activaNavegacio("ajax");
						var pare=$(o).parents(".Extensible");
						$(o).parents(".Extensio").addClass("ocult");
						//Restauro la visibilitat dels botons i amago la imatge de càrrega
						$(o).parents("tr").removeClass("ocult");
						$(pare).append($(data));
						trvincle($(pare).find("tbody:last-child").prev("tbody"));
						segrestaNavegacio();
						/*Si es una taula de tipus desplegable (rectificacions), cal enviar
						una senyal per avisar de que cal asociar les files recuperades*/
						if(callbackfn!==null){callbackfn();}
						//al fer servir desplegable, cal enviar-li senyals de que s'han recuperat dades
						if(isFunc("desplegable")){
							$(".Desplegable").trigger("update");
						}
					});
				}
			}
		;
		
		if(!pattern.test(window.location.href)){
			//if(typeof(inhibidorExtensible)==="undefined"){
			segrestaNavegacio();
			//}
		}
			
		$(".Extensible").bind("changeData", function(){
			segrestaNavegacio();
		});
			
		if($(".Extensible").length){
			addFunc("extensible");
		}
		$("tbody.darrer").next("tbody").find("ul li.seg a").attr({"role":"button","aria-expanded":false});
		enriqueixTaules();
	}
}
	