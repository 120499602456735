/*jshint esversion: 6*/

import alertARIA from "./alertaria.js";
import nooutline from "./nooutline.js";
import getNextAriaControlsId from "./getnextariacontrolsid.js";

/**
 * WAI: Si volem que la taula mostri o oculti el contingut via WAIocult, per defecte .show() i .hide()
 * ex: https://www.idescat.cat/dev/visual/
 */
export default function llistad(){
	alertARIA();//inicialitzem l'element d'avisos per l'accessibilitat
	if($(".LlistaD").length){
		const
			LLISTAWAIOCULT = $(".LlistaD.plegat.WAI").length,
			WAIOCULT = "WAIocult"
		;
		var
			anima=function(e,o){
				var f=(o) ?
					function() {e.show(null);} : function() {e.hide(null);};
				e.animate({height: "toggle"}, 100, f);
			},
			h2Plegat =$(".LlistaD.plegat h2"),
			plegaDesplegaTot=function(h2){
				//obrir tot
				if(!h2.hasClass("desplegat")){
					h2
						.addClass("desplegat")
						.attr("aria-label", IDESCAT.msg.llista.menys+" "+h2.text())
					;
					h2.nextAll("h3")
						.addClass("desplegat")
						.attr("aria-expanded", "true")
					;
					if (LLISTAWAIOCULT) {
						h2.nextAll("div.Cont").each(function () {
							let element = $(this);
							element.removeClass(WAIOCULT);
							element.children("iframe").removeClass(WAIOCULT);
						});
					} else {
						h2.nextAll("div.Cont").show();
					}
					h2.data("oberts", h2.nextAll("h3").length);
					alertARIA(IDESCAT.msg.llista.desplegat);
				}else{ //tancar tot
					h2
						.removeClass("desplegat")
						.attr("aria-label", IDESCAT.msg.llista.mes+" "+h2.text())
					;
					h2.nextAll("h3")
						.removeClass("desplegat")
						.attr("aria-expanded", "false")
					;
					if (LLISTAWAIOCULT) {
						h2.nextAll("div.Cont").each(function () {
							let element = $(this);
							element.addClass(WAIOCULT);
							element.children("iframe").addClass(WAIOCULT);
						});
					} else {
						h2.nextAll("div.Cont").hide();
					}
					h2.data("oberts", 0);
					alertARIA(IDESCAT.msg.llista.plegat);
				}
			}
		;

		h2Plegat.each(function(index, element){
			var
				h2 = $(element)
			;

			h2
				.data("oberts", 0)
				.attr("tabindex", "0")
				.attr("role", "button")
				.attr("aria-label", IDESCAT.msg.llista.mes+" "+h2.text())
				.click(function(){//Plega/desplega TOT
					plegaDesplegaTot($(this));
				})
				.keyup(function(e){
					if(e.key==="Enter"){
						plegaDesplegaTot($(this));
					}
				})
			;
			nooutline(h2Plegat);
		});


		$(".LlistaD.plegat h3").each(function(index, element){
			var
				h3 = $(element),
				idariacontrols = getNextAriaControlsId(),
				updatePlegarEstendre = function(uh3){
					var
						ph2 = uh3.parent().find("h2"),
						e = uh3.next(".Cont"),
						iframe = $(e).children("iframe")
					;
					if(!uh3.hasClass("desplegat")){ //show
						if (LLISTAWAIOCULT) {
							e.removeClass(WAIOCULT);
							iframe.removeClass(WAIOCULT);
						} else {
							anima(e, true);
						}
						uh3.addClass("desplegat");
						ph2.data("oberts", ph2.data("oberts") + 1);
						uh3.attr("aria-expanded", "true");
					}else{ //ocultar
						if (LLISTAWAIOCULT) {
							e.addClass(WAIOCULT);
							iframe.addClass(WAIOCULT);
						} else {
							anima(e, false);
						}
						uh3.removeClass("desplegat");
						ph2.data("oberts", ph2.data("oberts") - 1);
						uh3.attr("aria-expanded", "false");
					}
					//si s'han obert tots, llavors el boto principal tancara tot
					if(ph2.data("oberts")==ph2.nextAll("h3").length){
						ph2.addClass("desplegat");
					//si s'han tancat tots, llavors el boto principal obrira tot
					}else{
						ph2.removeClass("desplegat");
					}
				}
			;

			if (LLISTAWAIOCULT) {
				h3.next(".Cont").addClass(WAIOCULT).css("display", "block");
				$("div.Cont iframe").addClass(WAIOCULT);
			}
			nooutline(h3);

			//Accessibilitat
			h3
				.attr("tabindex", "0")
				.attr("role", "button")
				.attr("aria-controls", idariacontrols)
				.attr("aria-expanded", h3.hasClass("desplegat") ? true : false)
				.next("div")
					.attr("id", idariacontrols)
			;

			h3
				.click(function(){
					updatePlegarEstendre($(this));
				})
				.keyup(function(e){
					if(e.key==="Enter"){
						updatePlegarEstendre($(this));
					}
				})
			;
		});
	}
}//llistad()
