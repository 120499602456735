/*jshint esversion: 6*/

export default function doble(){
  if($(".Doble").length){
    //click als radio input
    $(".Doble li input[type='radio']").click(function(){
      var
        fsSel=$(this).closest("fieldset"),
        chkBox=$(this).parent().find("label input[type='checkbox']")
      ;
      if(!$(fsSel).hasClass("selected")){
        var	fieldsets=$(this).closest("div").find("fieldset");
        $(fieldsets).removeClass("selected");  //treure class 'selected' del tots
        $(fsSel).addClass("selected"); //afegir class 'selected' al fieldset clicat
        $(fieldsets).not(".selected").find("input:checked").prop("checked", false); //uncheck els inputs del fieldset no seleccionat
      }
      //unckeck de tots els inputs de tipus checkbox
      $(this).closest("ul").find("input:checked[type='checkbox']").prop("checked", false);
      //ckeck del input de tipus checkbox que estigui associat al radio input clicat
      if(chkBox.length){ chkBox.prop("checked", true); }
    });

    //click als checkbox input
    $(".Doble li input[type='checkbox']").click(function(){
      //només es marca el radio button al marcar checkbox
      if($(this).prop("checked")){
        $(this).closest("li").children("input[type='radio']").click();
      }
    });

    //segrestar click sobre el botó inactiu per tal que no faci res
    $(".Doble input.Boto").click(function(){
      if(!$(this).closest("fieldset").hasClass("selected")){
        return false;
      }
    });
  }
}//doble()
