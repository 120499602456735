/*jshint esversion: 6*/

function validavalor(p){

  var
    inputVal=p.input,
    output=(typeof p.output==="string") ? $(p.output) : p.output,
    valid=p.valid,
    //mostra per pantalla un missatge d'error
    mostrarError=function(errorMsg, output){
      //el paràmetre "output" és opcional.
      if(typeof output==="undefined"){
        window.alert(errorMsg);
      }else{
        $(output).html('<p role="alert">' + errorMsg  + "</p>");//FLM20190717
      }
    }
  ;

  for(var i=0, len=valid.length, valor=valid[i].valor; i<len; i++){
    switch(valid[i].tipus){
      case "ncars":
        if(inputVal.length < valor){
          mostrarError(IDESCAT.msg.pocsCaracters + valor +
            ((valor>1) ? IDESCAT.msg.caracters : IDESCAT.msg.caracter), output);
          return false; //validació ko
        }
      break;
      case "data":

        var matches=null;
        //comprovar format
        if(valor==="dd-mm-aaaa"){
          matches=/^(\d{2})-(\d{2})-(\d{4})$/.exec(inputVal);
        }else if(valor==="aaaa-mm-dd"){//dd-mm-aaaa
          matches=/^(\d{4})-(\d{2})-(\d{2})$/.exec(inputVal);
        }

        if(matches!==null){
          var
            dia=parseInt(matches[1], 10),
            mes=parseInt(matches[2], 10)-1,
            any=parseInt(matches[3], 10),
            d=new Date(any, mes, dia) // data introduïda
          ;
          if(d==="Invalid Date" || d.getDate()!==dia ||
            d.getMonth()!==mes || d.getFullYear()!==any){
              mostrarError(IDESCAT.msg.invalidDate, output);
              return false; //data invàlida
          }
        }else{//data invàlida
          mostrarError(IDESCAT.msg.invalidDate, output);
          return false;
        }
      break;
      case "passat":
        //var matches=/^(\d{2})-(\d{2})-(\d{4})$/.exec(inputVal);
        if(matches!==null){
          var
            date=new Date(parseInt(matches[3], 10),
              parseInt(matches[2], 10)-1, parseInt(matches[1], 10)) // data introduïda
          ;
          //comprovar si la data introduïda no és futura
          if(date.getTime() > new Date().getTime()){
            mostrarError(IDESCAT.msg.invalidDate, output);
            return false; //data invàlida
          }
        }else{//data invàlida
          mostrarError(IDESCAT.msg.invalidDate, output);
          return false;
        }
      break;
    }
  }

  return true;

}//fi validaValor()



//activa validació d'un input ( es pot cridar des del peu o des de sug() )
export default function validainput(p){
  var input=$(p.input);
  //no s'ha fer submit si el text és buit
  input.closest("form").submit(function(e){
    //XMB20190621

    if(
      input.val().trim()!=="" &&
      validavalor({
        input: input.val(),
        output: p.output,
        valid: p.valid
      })
    ){
       return true; //retornem per si s'invoca externament (tot i que en principi la donarem de baixa externament)
    }else{
      e.preventDefault();
      $(this).attr("data-invalid", "true");//FLM20190621
      return false;
    }
  });
}
