/*jshint esversion: 6*/

export default function formules() {
    if ($('.formules').length) {
      var script = document.createElement('script'),
          scriptes6 = document.createElement('script') //IE11
      ;
      script.src = "/commonScripts/ext/node_modules/mathjax/es5/tex-svg.js";
      script.id = "MathJax-script";
      script.async = true;
      document.head.appendChild(script);
      scriptes6.src = "https://polyfill.io/v3/polyfill.min.js?features=es6";
      scriptes6.async = true;
      document.head.appendChild(scriptes6);
      window.MathJax = {
        // startup: {pageReady: function pageReady() {return MathJax.startup.defaultPageReady().then(function () {f.show();});}},
        // tex: {inlineMath: [['$', '$'], ['\\(', '\\)']], tags: 'none'},
        svg: {
          fontCache: 'global',
          displayAlign: 'left'
        },
        options: {
          sre: {
            json: '/commonScripts/ext/node_modules/mathjax/es5/sre/mathmaps' //Ubicació fitxers d'idioma (ca.js, es.js, etc.)
          },
          enableMenu: false,//Desactivem opció de menú de navegació
          a11y: {
            locale: 'es' //IDESCAT.lang
          },
          menuOptions: {
            settings: {
              explorer: false,
              renderer: 'SVG'
            }
          }
        }
      };
    }
}