/*jshint esversion: 6*/

import GAClic from "./gaclic.js";
import lineal from "./lineal.js";
import enriqueixTaules from "./enriqueixTaules.js";

if (window.location.hostname.indexOf("idescat") === 0) {   //http(s)://idescat.cat  => https://www.idescat.cat
  window.location.href = window.location.href.replace(/http[s]?:\/\/idescat\.cat/gm, 'https://www.idescat.cat')
}else if(window.location.hostname.indexOf("idescat.cat")>0 && window.location.protocol==="http:"){ /*1.5.4*/
  window.location.protocol="https:";
}

function httpshref(location){
  return location.protocol==="http:" ? location.href.replace(/^http:/, "https:") : location.href;
}

//Barra social: Opinar+Compartir (invocada per ini())
function barrasocial(){
  var
    biblio=$("#bibliobs"),
    nobiblio=(biblio.length===0),
    idescat = window.location.host !== "biblio.idescat.cat",
    domain= idescat ? "" : "https://www.idescat.cat",
    bibpar= idescat ? "" : "&biblio=1",
    content=
    '<aside id="barrasocial" role="complementary">'+
      '<ul>'+
        '<li>'+
          '<div id="bsopinar" tabindex="0" role="button" aria-expanded="false" aria-controls="bsquest"><img role="presentation" src="'+domain+'/images/ico/opina.png" alt="" />'+IDESCAT.msg.bsocial.opinar+'</div>'+
        '</li>'+
        '<li class="compartir">'+
          '<h2><img role="presentation" src="'+domain+'/images/ico/compartir.png" alt="" />'+IDESCAT.msg.bsocial.compartir+'</h2>'+
          '<ul></ul>'+
        '</li>'+
      '</ul>'+
      '<div role="region" id="bsquest" aria-labelledby="bsopinar"><img src="'+domain+'/images/carrega.gif" title="'+IDESCAT.msg.carregant+'" alt="'+IDESCAT.msg.carregant+'"/></div>'+
    '</aside>'
  ;

  //No biblio (Estàndards i no estàndards) i biblio
  if(nobiblio){
    $("main").children("div.aplec").append( content );
  }else{
    biblio.html('<link rel="stylesheet" type="text/css" media="all" href="https://www.idescat.cat/estils/bibliobs.css" /><div class="aplec">' + content + '</div>');
  }

  var
    ios=!!(window.navigator.userAgent.match(/ipad|ipod|iphone/i)), //Ecs!!!
    barrasocial=$("#barrasocial"),
    compartir=barrasocial.children("ul").children("li.compartir"),
    bsquest=barrasocial.children("#bsquest"),
    getURL=function(id){
      var
        wl= window.location,
        href=httpshref(wl),
        hash=wl.hash.slice()
      ;
      href=href.replace(hash, "");//Blanquejem hash
      return href+(href.indexOf("?") !== -1 ? "&" : "?")+"utm_campaign=peu&utm_medium=social&utm_source="+id+hash;
    },
    xarxes=[{
        "id" : "X (Twitter)",
        "url": "https://twitter.com/intent/tweet?via=idescat&url=",
        "text": "&text=",
        "img": "xq.png"
      },{
        "id" : "Facebook",
        "url": "https://www.facebook.com/sharer/sharer.php?u=",
        "text": null,
        "img": "facebookq.png"
      },{
        "id" : "LinkedIn",
        "url": "https://www.linkedin.com/sharing/share-offsite/?url=",
        "text": null,
        "img": "linkedinq.png"
      },{
        "id" : "WhatsApp",
        "url": "https://wa.me/?text=",
        "text": null,
        "img": "whatsappq.png"
      },{
        "id" : "Email",
        "url": "mailto:?body=",
        "text": "&subject=",
        "img": "emailq.png"
      }
    ],
    xarxahref=function(id){
      var
        urlesc=encodeURIComponent( getURL(id ) ),
        text=encodeURIComponent(document.title),
        xarxa=xarxes.filter(function(e){return e.id===id;})[0]
      ;

      if(id!=="WhatsApp"){
        return xarxa.url+urlesc+(xarxa.text ? xarxa.text+text : "");
      }else{
        return xarxa.url+text+"%20"+urlesc;
      }
    },
    linkhref=function(){
      return getURL("link");
    },
    getBarraSocial=function(){
      var
        llista=""
      ;

      xarxes.forEach(function(e){
        var id=e.id;
        llista+='<li><a data-xarxa="'+id+'"'+ (id!="Email" ? 'target="_blank" rel="noopener" ' : '') +'title="'+IDESCAT.msg.bsocial[id]+'" href="'+xarxahref(id)+'"><img src="'+domain+'/images/ico/'+e.img+'" alt="'+id+'"></a></li>';
      });

      //Afegim l'opció de copiar enllaç. Sense readonly="readonly" per iOS9 (iPads antics p.e.)
      llista+='<li>'+
        '<form id="copiarurl"><label class="WAIocult" for="copiarurlinput">URL</label><input id="copiarurlinput" type="text" name="url" value="'+linkhref()+'" title="URL"/></form>'+
        '<img tabindex="0" role="button" aria-expanded="false" aria-controls="copiarurl" src="'+domain+'/images/ico/linkq.png" alt="'+IDESCAT.msg.bsocial.copiaradreca+'"  title="'+IDESCAT.msg.bsocial.copiaradreca+'" />'+
        '</li>'
      ;
      return llista;
    },
    ocultalink=function(){
      compartir.removeClass("visible");
      copiarimg.attr("aria-expanded", "false");
      compartir.find(".msg").remove();
    },
    timeout=function(id){
      if(id){
        window.clearTimeout(id);
      }
      return window.setTimeout(function(){ ocultalink(); }, 10000);
    }
  ;

  //Carreguem la llista de xarxes socials
  compartir.children("ul").append( getBarraSocial() );

  var
    timerid,
    copiarurl=compartir.find("#copiarurl"),
    link=copiarurl.children("input[name=url]"),
    copiarimg=compartir.find("#copiarurl + img"),
    bsopinar=barrasocial.children("ul").children("li").children("#bsopinar"),

    copiar=function(){
      link.attr("value",linkhref());
      compartir.removeClass("copiat");
      if(compartir.hasClass("visible")){
        ocultalink();
      }else{
        compartir.addClass("visible");
        copiarimg.attr("aria-expanded", "true");
        timerid=timeout(null);
      }
    },
    enter=function(evt){
      if(evt.key==="Enter"){
        copiar();
      }
    },
    error=function(){ //Error en càrrega de questionari Opinar
      bsopinar
        .hide()
        .attr("hidden", true) //html5
        .attr("aria-hidden", "true") //aria
      ;
    }
  ;

  copiarimg.click(copiar).keyup(enter);

  link.on(ios ? "click" : "focus", function(){
    var	el=link.get(0);

    if(ios) {
      var range=document.createRange();

      range.selectNodeContents(el);

      var sel=window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      el.setSelectionRange(0, 999999);
    }else{
      link.select();
    }

    el.contentEditable=false;
    el.readOnly=true;

    if(document.execCommand && document.execCommand("copy")){
      copiarurl.after('<p class="msg" role="alert">'+IDESCAT.msg.bsocial.adrecacopiada+'</p>');
      compartir.addClass("copiat");
      copiarimg.focus();
    }

    timeout(timerid);
  });

  //Element compartits amb quest Ajax
  IDESCAT.DOM={
    bsopinar: bsopinar,
    compartir: compartir,
    bsquest: bsquest,
    copiarimg: copiarimg
  };

  $.ajax({
    dataType: "html",
    type: "GET",
    url: domain + "/utils/opina/?v=2" + bibpar + "&lang=" + IDESCAT.lang, //XMB20200225 Adreça deixa de ser relativa per poder executar a biblio.idescat.cat. A més passem a v2 pq canviem format de presentació de la valoració 1-5
    success: function(data){
      if(data!==""){
        bsquest.html(data);
      }else{
        error();
      }
    },
    error: function(){
      error();
    }
  });
  compartir.children("ul").find("a").on("focus", function(){
    $(this).attr("href",xarxahref($(this).data("xarxa")));
  });
}

//XMB20190517 Experimental ATENCIÓ: barra (aside) es fica fins de main! (Cap problema en principi i ha de ser així per presentació .aplec)
export default function ini(){
	if(!$("main").length){
    $("#Titular, #Contingut > div.aplec, #Mx > div.aplec").wrapAll("<main />");
  }
	if(!$("footer#Peu").length){
    $("#Peu").wrapAll('<footer role="contentinfo" />'); //https://stackoverflow.com/questions/11191746/should-role-contentinfo-be-always-added-on-footer-element
		/* En realitat hauria de ser (però fa doble càrrega d'imatges)
			$("#Peu").replaceWith( "<footer id='Peu'>" + $("#Peu").html() + "</footer>" );
		*/
  }

	//barra de menú: Dades, Serveis, Mètodes i qualitat, L'Institut
	$("nav h2").click(function(){
		var
			padding=20, /* separació entre barra i tab obert */
			el=$(this).parent("li"),
			link=$(this).children("a"),
			barra=$("#BarraSituacio, #DNI"), //home no té #BarraSituacio sinó #DNI
			selected=$("nav li.selected")
		;

		if(!el.hasClass("selected")){
			selected.removeClass("selected");
			selected.children("h2").children("a").attr("aria-expanded","false");

			el.addClass("selected");
			link.attr("aria-expanded","true");//FLM20190603

			barra.css({"margin-top": $(".selected .tab").innerHeight()+padding });
		}else{
			el.removeClass("selected");
			link.attr("aria-expanded","false");//FLM20190603

			barra.css({ "margin-top": 0 }); /* Originalment és 0 */
		}
		return false;
	});

	var
		input=$("#cercador input[name='q']"),
		val=input.attr("aria-label").toLowerCase(),
		ancores=document.getElementsByTagName("a"),
		f=function(){return GAClic(this);}
	;

	input.val(val)
		.trigger("blur")
		.bind("focus", function(){
			if($(this).val()===val){
				$(this).val("");
			}
		})
		.bind("blur", function(){
			$(this).val(val);
		})
	;

	//no fer submit si el text del input és buit o és el que hi havia per defecte
	input.parent().submit(function(){
		var str=input.val();
		if(str.trim()==="" || val===str){
			return false;
		}
	});

	//segrest enllaços per analítica web (estava a WebTrends() abans) PENDENT: reescriure en jQuery
	for(var i=0, z=ancores.length; i<z; i++){
		if(ancores[i].onclick===null){
			ancores[i].onclick=f;
		}
	}

	$(".amunt").click(function() {
		$("html, body").animate({scrollTop: 0}, "slow");
	});

	if(
		!$("#barrasocial").length //Impedir doble execució
		//Eliminada aquesta excepció a v1.1.21 (habilitació de barra social i opina a biblio XMB20200714): && window.location.hostname.slice(0,7)!=="biblio."
		){
	  	barrasocial();
	}

	//Accessibilitat (integrar amb ancores GAclic()?)
	$("a.extern").each(function(){
    var
      html=$(this).html(),
      title=$(this).attr("title")
    ;

    //Impedir dobles execucions o afegir WAIocult quan ja existia
    if(!$(this).find(".WAIocult").length){
      $(this).html(html+'<span class="WAIocult"> ('+title+')</span>');
    }
  });

	//No tenim en compte si canvia orientació: ens quedem amb condicions més desfavorables
	var width=Math.min(window.screen.width, window.screen.height);

	if(width<=740){ //mobile
		lineal();//afegir "lineal" a ApartNum si s'escau

		var h=$(document).height()-1100; //deixem marge per peu  (800+300)

		$(window).scroll(function() {
			var scroll=$(this).scrollTop()-300; //deixem marge per encapçalament
			//console.log("height="+$(document).height()+" scroll="+scroll+" h="+h);
			if(scroll>0 && scroll<h) {
				$("#pujar").fadeIn();
			}else{
				$("#pujar").fadeOut();
			}
		});
		//esborrem cookie si hi és (només si s'està jugant amb eines per desenvolupadors de Chrome)
		document.cookie="desk=; expires=Wed, 14 Dec 2016 12:39:19 GMT; path=/";
	}else{ //Desktop: posar cookie per indicar que no cal enriquir cel·les de taules
		document.cookie="desk=1; expires=Mon, 02 May 2044 10:57:28 GMT; path=/";
	}

  if(!$('.cod, .lit')){
    enriqueixTaules();
  }
}
