/*jshint esversion: 6*/

import lineal from "./lineal.js";

//incrusTable per entendre'ns {id: "embed", url: {relativa per CORS}, n: 1 (Pot ser "*"), tfoot: false, callback [per defecte lineal]}
export default function incrustable(p){
  //url i id requerits
  if(typeof p.url==="undefined" || typeof p.id==="undefined"){
    return;
  }
  if(typeof p.n==="undefined"){
    p.n=1;
  }

  var
    n=(typeof p.n==="string") ? "*" : (p.n)-1,
    sel=(n==="*") ? "" : ":eq("+n+")",
    id=$("#"+p.id),
    //XMB20210126
    callback=(typeof p.callback==="function") ? p.callback : lineal
  ;

  if(!!!p.tfoot){
    id.addClass("tfootless");
  }

  id.wrap('<div class="scroll"></div>');
  id.load(
    p.url + " .ApartNum" + sel,
    callback //mobilitzar ApartNum si s'escau (=afegir classe lineal) o qualsevol altra transformació que es vulgui efectuar
  );
}
