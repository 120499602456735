/*jshint esversion: 6*/

//FLM202011006 creació fitxer
//NOTA: modes 'iniordered' i 'multi' estan comentats ja què no s'utilitzen a cap pàgina.
//PENDENT: tipus 'iniordered' pendent de validació.
/**
 *	Possibilitat de marcar en negreta mots d'un array de strings
 * @param {object} :
 * 	mode : tipus de destacat :
 *   · "normal" : marcació no estricta de la paraula(no té en compte accents, dierisis, etc...)
 *   · "strict" : marcació estricta de la paraula (té en compte accents, dieresis, etc...)
 *   · "ini" : (UTILITZAT A CLASSIFICACIONS) marcació mots començament de paraula, no es tindra en compte l'ordre dels mots d'entrada("prod ind" o "ind prod")
 *   · "iniordered" : marcació mots començament de paraula, SÍ importa l'ordre dels mots d'entrada
 *   · "multi" : marcació mots a qualsevol posició de la paraula, no es tindra en compte l'ordre dels mots d'entrada
 * 	haystack : llista de strings
 * 	needle : mot/s a marcar en negreta
 *@return {array} amb els mots marcats en negreta(<strong>XXX</strong>)
 */
export default function destaca(o) {
  var
    o = (typeof o !== 'undefined') ? o : {},
    mode = (typeof o.mode !== 'undefined') ? o.mode : "normal",
    haystack = (typeof o.haystack !== 'undefined') && Array.isArray(o.haystack) ? o.haystack : [],
    needle = (typeof o.needle !== 'undefined') ? o.needle : "",
    normalitzar = function(s) {
      var s1 = 'àáèéìíïòóùüú';
      var s2 = 'aaeeiiioouuu';
      for (var i = 0; i < s1.length; i++) {
        s = s.replace(new RegExp(s1.charAt(i), 'g'), s2.charAt(i));
      }
      return s.trim().replace(/\s+/g, " ");
    },
    normalitzarCeTrancada = function(s) {
      return s.replace("ç", "c");
    },
    result = [];
  haystack.forEach(function(item) {
    var termnorm, termori, requestnorm, match, patt, itemlabel, regex = "",
      lastIndexFrom = 0,
      itemlabelAux = "",
      matches, start = 0,
      offset = 0,
      strResult = "",
      aux = [];
    requestnorm = normalitzar(needle);
    switch (mode) {
      case 'strict': //Es respecten accents i caràcters especials
        result.push({
          value: item,
          label: item.replace(new RegExp("(" + $.ui.autocomplete.escapeRegex(needle) + ")", "ig"), "<strong>$1<\/strong>")
        });
        break;
      case 'ini'://UTILITZAT A CLASSIFICACIONS
        termnorm = normalitzarCeTrancada(normalitzar(item)); //Normalitzem ç en aquest cas ja que mai ens vindrà una ç a principi de paraula. Problema regex ('\ba') i tenim 'dolça', marca dolç'a' com a principi de paraula.
        regex = requestnorm = normalitzarCeTrancada(normalitzar(needle));
        regex = "(\\b" + requestnorm.split(" ").join('|\\b') + ")";
        patt = new RegExp(regex, "gi");
        itemlabel = item;
        while ((match = patt.exec(termnorm))) {
          itemlabelAux += itemlabel.slice(lastIndexFrom, match.index) + "<strong>" + itemlabel.slice(match.index, match.index + match[0].length) + "</strong>";
          lastIndexFrom = match.index + match[0].length;
        }
        itemlabel = itemlabelAux + itemlabel.slice(lastIndexFrom, itemlabel.length);
        result.push(itemlabel);
        break;
        default: //normal
          termnorm = normalitzar(item);
          requestnorm = normalitzar(needle);
          patt = new RegExp(requestnorm, "gi");
          itemlabel = item;
          while ((match = patt.exec(termnorm))) {
            termori = item.slice(match.index, match.index + match[0].length);
            itemlabel = itemlabel.replace(termori, "<strong>" + termori + "</strong>");
          }
          result.push(itemlabel);
          break;
/*      case 'iniordered':
        termnorm = normalitzar(item);
        requestnorm = normalitzar(needle);
        regex = ("(\\b" + requestnorm + ")");
        if (requestnorm.indexOf(" ") !== -1) {
          requestnorm.split(" ").forEach(function(term) {
            aux.push("(\\b" + term + ")");
          });
          regex = aux.join(".+");
        }
        patt = new RegExp(regex, "igm");
        itemlabel = item;
        if (requestnorm.indexOf(" ") !== -1) {
          matches = patt.exec(termnorm);
          if (matches) {
            matches.shift();
            matches.forEach(function(match, i) {
              offset = start + termnorm.slice(start, termnorm.length).search('\\b'+match);
              strResult += itemlabel.slice(start, offset) + "<strong>" + itemlabel.slice(offset, offset + match.length) + "</strong>";
              start = offset + match.length;
            });
            strResult += itemlabel.slice(start, itemlabel.length);
            itemlabel = strResult;
          }
        } else {
          while ((match = patt.exec(termnorm))) {
            itemlabelAux += itemlabel.slice(lastIndexFrom, match.index) + "<strong>" + itemlabel.slice(match.index, match.index + match[0].length) + "</strong>";
            lastIndexFrom = match.index + match[0].length;
          }
          itemlabel = itemlabelAux + itemlabel.slice(lastIndexFrom, itemlabel.length);
        }
        result.push(itemlabel);
        break;
      case 'multi':
        termnorm = normalitzar(item);
        regex = requestnorm = normalitzar(needle);
        if (requestnorm.indexOf(" ") !== -1) {
          regex = "(" + requestnorm.split(" ").join('|') + ")";
        }

        patt = new RegExp(regex, "gi");
        itemlabel = item;
        if (requestnorm.indexOf(" ") !== -1) {
          lastIndexFrom = 0, itemlabelAux = "";
          while ((match = patt.exec(termnorm))) {
            match.shift();
            itemlabelAux += itemlabel.slice(lastIndexFrom, match.index) + "<strong>" + itemlabel.slice(match.index, match.index + match[0].length) + "</strong>";
            lastIndexFrom = match.index + match[0].length;
          }
          itemlabel = itemlabelAux + itemlabel.slice(lastIndexFrom, itemlabel.length);
        } else {
          match = patt.exec(termnorm);
          if (match) {
            termori = item.slice(match.index, match.index + match[0].length);
            itemlabel = itemlabel.replace(new RegExp(termori, 'gm'), "Ѫ" + termori + "Ѫ");
            itemlabel = itemlabel.replace(new RegExp("(Ѫ)(" + termori + ")(Ѫ)", 'gm'), '<strong>$2</strong>');
          }
        }
        result.push(itemlabel);
        break;
*/
    }
  });
  return result;
}
