/*jshint esversion: 6*/

/*
	Esperem dos tipus de crides per aquesta funció:
		- Per iniciar Google Analytics i el Tag Manager:
			analitica({"seccio": seccio, "tema": tema, "estad": estad, "prod": prod})
		- Per marcar algun dels nostres esdeveniments:
			analitica({"name": "Cercador"|"CercaSugg"|"Download", "category": category, "label": label})
*/

export default function analitica(data) {
	function isdef(obj, prop){
		return (typeof obj==="object" && obj[prop]) ? obj[prop] : "(no def)"; 
	}

	//És esdeveniment real
	if (data.hasOwnProperty("name")) {
		//ga(,,eventCategory,eventAction,eventLabel);
		const 
			name=isdef(data, "name"),
			category=isdef(data, "category"),
			label=isdef(data, "label")
		;

		/* AMG20231103 Deshabilitem el codi de l'antic analytics
		if (typeof ga === "function" ) {
			ga("send", "event", name, category, label);
		}
		*/
		if (typeof gtag === "function" ) {
			gtag("event", name, {
				"event_category": category,
				"event_label": label
			});
		}
	} else {
	//Inicialització. És page_view
		// GA4
		window.dataLayer = window.dataLayer || [];
		window.gtag=function(){dataLayer.push(arguments);};

		// UA isogram
		/* AMG20231103 Deshabilitem el codi de l'antic analytics
		window.GoogleAnalyticsObject="ga";
		window.ga = window.ga || function(){ (window.ga.q=window.ga.q || [] ).push(arguments); };
		window.ga.l = new Date().getTime();
		const 
			a = document.createElement("script"),
			m = document.getElementsByTagName("script")[0]
		;
		a.async = true;
		a.src = "//www.google-analytics.com/analytics.js";
		m.parentNode.insertBefore(a,m);*/

		// GTM GENCAT
		//window.dataLayer=window.dataLayer || [];
		window.dataLayer.push({"gtm.start": new Date().getTime(), event:"gtm.js"});
		const 
			f = document.getElementsByTagName("script")[0],
			j = document.createElement("script")
		;
		j.async = true;
		j.src = "https://www.googletagmanager.com/gtm.js?id=GTM-NH8MVLN";
		f.parentNode.insertBefore(j,f);

		const 
			lang=isdef(IDESCAT, "lang"),
			seccio=isdef(data, "seccio"),
			tema=isdef(data, "tema"),
			estad=isdef(data, "estad"),
			prod=isdef(data, "prod")
		;

		/* AMG20231103 Deshabilitem el codi de l'antic analytics
		ga("create", "UA-26838309-3", "auto");
		ga("require", "linkid", "linkid.js");
		ga("set", "contentGroup2", lang);
		ga("set", "contentGroup3", seccio);
		ga("set", "contentGroup4", tema);
		ga("set", "contentGroup1", estad);
		ga("set", "contentGroup5", prod);
		ga("send", "pageview");
		*/

		//ATENCIÓ: Canvis en el codi d'analitica.js poden comportar canvis a comu.php (analítica de la pàgina principal) 
		gtag("js", new Date());
		//JME20250108: Canviem el codi de seguiment per afegir els paràmetres de content_group
		gtag("config", 'G-0Z6NJ7PL6K', {
			"content_group": estad,
			"content_group_lang": lang,
			"content_group_seccio": seccio,
			"content_group_tema": tema,
			"content_group_prod": prod
	  	});

		gtag("event", "gc", {
			"lang": lang,
			"seccio": seccio,
			"tema": tema,
			"estad": estad,
			"prod": prod
		});		
	}
}