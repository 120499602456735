/*jshint esversion: 6*/

export default function rel(o){
	//No hi ha més dades si no hi ha enllaç al producte alternatiu ni a estadística
	if((o.ue===null && o.aec===null && o.ibc===null) && (o.detall===null || o.estad===null)){
		return;
	}

	const 
		lang=IDESCAT.lang,
		text={
			prod: {
				'ibc': {'ca': 'Indicadors bàsics de Catalunya', 'es': 'Indicadores básicos de Cataluña', 'en': 'Basic indicators of Catalonia'},
				'aec': {'ca': 'Anuari estadístic de Catalunya', 'es': 'Anuario estadístico de Cataluña', 'en': 'Statistical Yearbook of Catalonia'}
			},
			ibc: {
				ca: 'Indicadors bàsics de Catalunya', 
				es: 'Indicadores básicos de Cataluña', 
				en: 'Basic indicators of Catalonia'
			},
			aec: {
				ca: 'Anuari estadístic de Catalunya', 
				es: 'Anuario estadístico de Cataluña', 
				en: 'Statistical Yearbook of Catalonia'
			},
			ue: {
				ca: 'Indicadors de la Unió Europea',
				es: 'Indicadores de la Unión Europea',
				en: 'European Union Indicators'
			},
			art: { 'ca': 'les', 'es': 'los', 'en': 'the'},
			podeu: {
				'ca': 'Podeu trobar',
				'es': 'Puede encontrar',
				'en': 'You can find'
			},
			indicador: {
				'ca': 'd\'aquest indicador ',
				'es': 'de este indicador ',
				'en': 'on this indicator '
			},
			prodart: {
				'aec': {ca:'a l\'', es:'en el ', en: 'in the '},
				'ibc': { ca: 'als ', es: 'en los ', en: 'in the ' },
				'ue': {ca:'als ', es:'en los ', en: 'at '}
			},
			font: {
				'ca': 'Font d\'aquest indicador',
				'es': 'Fuente de este indicador',
				'en': 'Source of this indicator'
			},
			detallats: {
				ca: '<strong>resultats més detallats</strong> a la font d\'aquest indicador',
				es: '<strong>resultados más detallados</strong> en la fuente de este indicador',
				en: '<strong>more detailed results</strong> in the source of this indicator'
			},
			altres: {
				ca: 'altres dades',
				es: 'otros datos',
				en: 'other data'
			},
			indue: {
				ca: 'Podeu trobar <strong>dades de la Unió Europea</strong> d\'aquest indicador ',
				es: 'Puede encontrar <strong>datos de la Unión Europea</strong> de este indicador ',
				en: '<strong>European Union data</strong> for this indicator can be found '
			}
		},
		periodeAEC = {
			'ca': IDESCAT.msg.dades.toLowerCase() + ' anuals',
			'es': IDESCAT.msg.dades.toLowerCase() + ' anuales',
			'en': 'annual ' + IDESCAT.msg.dades.toLowerCase()
		},
		msg={
			dades: IDESCAT.msg.dades.toLowerCase(),
			art: text.art[lang],
			font: text.font[lang],
			detallats: text.detallats[lang],
			indicador: text.indicador[lang],
			podeu: text.podeu[lang],
			aec: text.aec[lang],
			ibc: text.ibc[lang],
			ue: text.ue[lang]
		}
	;

	//const period=(o.id==="ibc") ? 'A' : o.period;
	const period = o.period;
	
	switch(period){
		case "D": msg.period={ 'ca': "decennals", 'es': 'decenales' }; break;
		case "W": msg.period={ 'ca': "quinquennals", 'es': 'quinquenales' }; break;
		case "Q": msg.period={ 'ca': "quadriennals", 'es': "cuatrienales" }; break;
		case "N": msg.period={ 'ca': "biennals", 'es': "bienales" }; break;
		case "A": msg.period={ 'ca': "anuals", 'es': "anuales" }; break;
		case "S": msg.period={ 'ca': "semestrals", 'es': "semestrales" }; break;
		case "T": msg.period={ 'ca': "trimestrals", 'es': "trimestrales" }; break;
		case "M": msg.period={ 'ca': "mensuals", 'es': "mensuales" }; break;
		case "I": msg.period={ 'ca': "amb periodicitat irregular", 'es': "con periodicidad irregular" }; break;
		case "U": msg.period = { 'ca': "amb periodicitat única", 'es': "con periodicidad única" }; break;
		case "R": msg.period = { 'ca': "triennals", 'es': "trienales" }; break;
	}

	
	msg.dperiod=(lang==='en') ? IDESCAT.msg.period[period].toLowerCase()+' '+msg.dades : msg.dades+' '+msg.period[lang];

	//No hi ha dades conjunturals S, T, M
	if ("STM".indexOf(o.period) === -1) {
		msg.dperiod = text.altres[lang];
		msg.aec_period = text.altres[lang];
		msg.art = "";
	} else { 
		msg.aec_period = periodeAEC[lang];
	}

	msg.indue = text.indue[lang] + text.prodart.ue[lang];
	msg.indaec = text.podeu[lang] + ' ' + msg.art + ' <strong>$p</strong> ' + text.indicador[lang] + text.prodart.aec[lang];
	msg.indibc = text.podeu[lang] + ' ' + msg.art + ' <strong>$p</strong> ' + text.indicador[lang] + text.prodart.ibc[lang];



	$("head").append('<link rel="stylesheet" href="/estils/rel.css" type="text/css" />');

	const msgaec = msg.aec,
		msgibc = msg.ibc;

	const
		aec   = o.aec ? '<p>' + msg.indaec.replace('$p', msg.aec_period)+'<a href="'+o.aec+'">'+msgaec+'</a>.</p>' : '',
		ibc   = o.ibc ? '<p>' + msg.indibc.replace('$p', msg.dperiod)+'<a href="'+o.ibc+'">'+msgibc+'</a>.</p>' : '',
		ue=o.ue ? '<p>'+msg.indue+'<a href="'+o.ue+'">'+msg.ue+'</a>.</p>' : '',
		mono=o.estad===null ? '' :
			(o.detall ? //true
				'<p>'+msg.podeu+' '+msg.detallats+': <a href="'+o.estad+'">'+o.nom+'</a> ('+o.codi.toUpperCase()+').</p>' 
				: //false
				'<p>'+msg.font+': <a href="'+o.estad+'">'+o.nom+'</a> ('+o.codi.toUpperCase()+').</p>'
			),
		//eq(): Cal posicionar el botó abans de les taules d'Espanya i traduir a índex (-1)
		pos = $(".scroll").length - $(".scroll #Espanya.js-ocult").length - 1
		;

	$(".scroll").eq(pos).after('<div class="desflota"><div id="info"><a tabindex="0" role="button" aria-expanded="false" aria-controls="infocont">'
		+ msg.dades
		+ '</a><div id="infocont">'
		+ aec
		+ ibc
		+ ue
		+ mono
		+ '</div></div></div>');

	const 
		a=$("#info > a"),
		obrir=function(){
			a.attr("aria-expanded")==="false" ? a.attr("aria-expanded", "true") : a.attr("aria-expanded", "false");
		}
	;

	a.on("click keyup", function(event){
		if((event.type==="keyup" && event.key==="Enter") || event.type==="click"){
			obrir();
		}
	});


}//rel()