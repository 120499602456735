/*jshint esversion: 6*/

import getNextAriaControlsId from "./getnextariacontrolsid.js";
import nooutline from "./nooutline.js";

export default function plegable(p){
	var
		wl=window.location,
		host=wl.protocol+"//"+wl.hostname
	;

	if ($(".ApartD.Plegable").length) {
		var	pleg=$(".ApartD.Plegable"), //lista de ApartD "plegables"
        h2elements = pleg.children("h2").filter(function() {
                return (!$(this).attr("role") || ($(this).attr("role") && $(this).attr("role") !== "button"));
            }); // Filtramos los h2 que no tienen el atributo role
		var
			addHash=(typeof p==="undefined") ? true : p.hash,
			h2 = h2elements,
			form=h2.next().children("form"), //llista de forms "plegables"
			hash=wl.hash, //#Plegable=id1,id2,..,idn&p2=a&p3=b&...&pn
			reg=/Plegable=(.[^\&]*)/,
			paramPl=hash.match(reg),
			idsForms=(paramPl!==null) ? paramPl[0].split("=")[1].split(",") : [],  //array d'ids dels forms que han canviat [id1,id2,id3...]
			toggle=function(that){
				var
					div=that.parent(), //div.ApartD.Plegable
					id=div.attr("id"),
					posId=jQuery.inArray(id, idsForms)
				;
				if(div.hasClass("plegat")){
					div.removeClass("plegat");
					that.attr("aria-expanded", "true");
				}else{
					div.addClass("plegat");
					that.attr("aria-expanded", "false");
				}
				//el form ha variat el seu estat, per tant s'afegeix a la llista de forms que han variat el seu estat inicial
				if(posId===-1){
					idsForms.push(id);
				}else{ //el form torna al seu estat inicial
					idsForms.splice(posId, 1);
				}
				$("body").data("data-plegable", idsForms);
			}
		;

		/************** Accessibilitat *********************/
		$.each(h2, function(index, ele){
			var h2 = $(ele),
				parent = h2.parent("div"),
				ariacontrols = getNextAriaControlsId();

			h2
				.attr("tabindex", "0")
				.attr("aria-label", h2.text())
				.attr("role", "button")
				.attr("aria-expanded", !parent.hasClass("plegat"))
				.attr("aria-controls", ariacontrols)
				.siblings("div:first")
					.attr("id", ariacontrols)
			;
		});

		/***************************************************/

		//comprovar si existeix algun plegat=id al href
		if(idsForms.length){
			for(var i=0; i<idsForms.length; i++){
				var plg=$("#"+idsForms[i]);
				if(plg.hasClass("plegat")){
					plg.removeClass("plegat");
				}else{
					plg.addClass("plegat");
				}
			}
			$("body").data("data-plegable",idsForms);
		}

		h2.children("span.nota").hide(); //ocultar T=Taula

		//click a les capçaleres 'h2'
		nooutline(h2);
		h2
			.click(function(evt){
				evt.stopPropagation();
				toggle($(this));
			})
			.on("keyup", function(evt){
				evt.stopPropagation();
				if(evt.key==="Enter"){
					toggle($(this));
				}
			})
		;

		if(addHash){
			//segrestar el submit per actualitzar href afegint-li un paràmetre amb la llista de ids de ApartD plegats: plegat=id1,id2,...
			form.submit(function(){
				//FLM20190621
				if($(this).attr("data-invalid")){
					$(this).removeAttr("data-invalid");
					return false;
				}
				window.location.href=host+wl.pathname+"?"+$(this).serialize()+
					((idsForms.length) ?
					(
						(paramPl!==null) ?
						hash.replace(reg, "Plegable="+idsForms.join(","))
						:
						((hash==="") ? "#" : hash+"&")+"Plegable="+idsForms.join(",")
					)
					:
					hash.replace(/(#|&|)Plegable=(.[^\&]*)/, "")
					)
			  ;
				return false;
			});
		}
	}

	var sintesi=$("#Sintesi");
	/* XMB20180604 */
	if(sintesi.length){
		var
			togsint=function(that){
				var div=that.parent("div");

				if(div.hasClass("plegat")){
					div.removeClass("plegat");
					that.attr("aria-expanded", "true");
				}else{
					div.addClass("plegat");
					that.attr("aria-expanded", "false");
				}
			}
		;

		nooutline(sintesi);

		//Accesibilitat
		sintesi
			.attr("role", "list")
			.children("div").attr("role", "listitem")
		;

		sintesi.find("h3").each(function(index, element){
			var
				h3 = $(element),
				idariacontrols=getNextAriaControlsId(),
				divparent=h3.parent("div")
			;

			nooutline(h3);
			h3
				.attr("tabindex", "0")
				.attr("role", "button")
				.attr("aria-expanded", !divparent.hasClass("plegat"))
				.attr("aria-controls", idariacontrols)
				.click(function(){
					togsint($(this));
				})
				.on("keyup", function(evt){
					if(evt.key==="Enter"){
						togsint($(this));
					}
				})
			;

			h3
				.next()//Detectats dos casos : El municipi en xifres(<form>...</form>) i Indicadors anuals(<ul></ul>)
				.wrap('<div id="'+idariacontrols+'"></div>')
			;
		});
	}

	/* FLM20180829 */
	$(".Filtre fieldset.Opcional legend").click(function(){
		var f = $(this).parent("fieldset");
		if(f.hasClass("plegat")){f.removeClass("plegat");} else {f.addClass("plegat");}
	});

}//plegable()
