/*jshint esversion: 6*/

export default function nooutline(element){
  $(element)
    .mouseover(function(){
      $(this).addClass("nooutline");
      /* AMG20240423 Afegit el remove class focus pel download.js */
      $(this).removeClass("outline");
    })
    .mouseleave(function(){
      $(this).removeClass("nooutline");
      /* AMG20221123 Afegit el remove class focus pel download.js */
      $(this).removeClass("outline");
      $(this).blur();//Eliminem el focus a l'element actiu
    })
  ;
}
