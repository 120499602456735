/*jshint esversion: 6*/

export default function placeholder(id, str){
  var el=$(id);
  if(el.length && typeof str==="string"){
    $(id)
      .attr("value", "") //utilitzem attr() i no val() perquè volem que no quedi ni rastre de valor de value que hi ha al codi HTML original
      .attr("placeholder", str.trim())
      .attr("spellcheck", "false")
    ;
  }
}// fi placeholder()
