/*jshint esversion: 6*/

import sug from "./sug.js";

//De moment no s'utilitza incrustació de taules: REHABILITAT EN PROVES
import incrustable from "./incrustable.js";

function amp(u){
	return u.replace(/&/g, "&amp;");
}

//NOTA: ELIMINAR RESTRICCIÓ DE VISUALITZACIÓ INCRUSTADOR(TAULES) A WWW.IDESCAT.CAT. CERCAR #FLM210701
export default function estad(id, privilegis){
	var
		barra=$("#BarraSituacio a"),
		href=barra.attr("href"),
		priv=(typeof privilegis==="undefined" || privilegis!==true) ? 0 : 1, //XMB20190130 Afegim privilegis
		params
	;

	/* Excepció temporal ua */
	if (id==="ua"){
		$("head").append('<link rel="stylesheet" href="/estils/id/rcaua.css" type="text/css" />');
		$("#sigles, #BarraSituacio > li ").remove();
		return;
	}

	/* De moment es mantenen per si si invoca on no toca */
	if( barra.length===1 && !$(".filera").length ){
		if(
			( IDESCAT.lang==="ca" && href==="/estad/" ) ||
			( IDESCAT.lang==="es" && href==="/estad/?lang=es" ) ||
			( IDESCAT.lang==="en" && href==="/estad/?lang=en" )
		){

			//1.0.45a s'afegeix excepció
			//1.1.2 Eliminem excepció IIG if(id!=="iig"){
			$("head").append('<link rel="stylesheet" href="/estils/estadistica.css" type="text/css" />');
			//$("body").addClass("Sintesi");
			/*1.1.2 Eliminem excepció IIG }else{
				$("head").append('<link rel="stylesheet" href="/estils/iig.css" type="text/css" />');
			}*/

			switch(IDESCAT.lang){
				case "es":
					IDESCAT.msg.infografia = "Infografía";
					break;
				case "en":
					IDESCAT.msg.infografia = "Infographic";
					break;
				default:
					IDESCAT.msg.infografia = "Infografia";
			}
			var
				i18n = IDESCAT.msg,
				titol = $("#Titol"),
				titext = titol.html(),
				start = $("#Titular"),
				aplec = $("main > div.aplec"),
				barrasocial = $("#barrasocial"),

				plantilla =
					'<div class="filera">' +
					'<aside class="esquerra">' +
					'<dl class="parell">' +
					'<dt>' + i18n.pdisponible + '</dt>' +
					'<dd id="disponible">...</dd>' +

					'<dt>' + i18n.periodicitat + '</dt>' +
					'<dd id="period">...</dd>' +

					'<dt>' + i18n.territ + '</dt>' +
					'<dd id="territ">...</dd>' +

					'<dt>' + i18n.topic + '</dt>' +
					'<dd id="tema">...</dd>' +
					'</dl>' +
					'</aside>' +
					'<div class="central"></div>' +
					'</div>',

				indic = function (json) {
					var
						tbody = "",
						arr = (json.indicadors.n !== "1") ? json.indicadors.i : [json.indicadors.i]
						;

					arr.forEach(function (e, i, a) {
						if (e.v !== "_") {//XMB20200703
							var
								val = Number(e.v).toLocaleString(IDESCAT.lang === "en" ? "en" : "ca"), //validar amb diversos navegadorsd desk/mob. AMG20240201 En castellà no va bé el toLocaleString
								unit = (e.u.hasOwnProperty("content") && e.u.content) ? "&thinsp;" + e.u.content : ""
								;

							/*XMB20190411*/
							if (e.v.substr(-2) === ".0") {
								val += (IDESCAT.lang === "en") ? ".0" : ",0";
							}

							//versió amb enllaç: tbody+='<tbody><tr><th scope="row"><a href="'+e.l+'">'+e.c+'</a></th><th class="temps"><a href="'+e.l+'">'+e.r.title+'</a></th><td rowspan="2" class="valor"><a class="noseparable" href="'+e.l+'">'+val+unit+'</a></td></tr><tr><th class="descr" colspan="2"><a href="'+e.l+'">'+e.d+'</a></th></tr></tbody>';
							tbody += '<tbody><tr><th scope="row">' + e.c + '</th><th class="temps">' + e.r.title + '</th><td rowspan="2" class="valor">' + val + unit + '</td></tr><tr><th class="descr" colspan="2">' + e.d + '</th></tr></tbody>';
						}
					});

					return (tbody !== "") //XMB20200703
						?
						'<table class="Indicadors"><caption class="WAIocult">' + i18n.indicaption + '</caption><thead><tr class="cap"><th scope="col">' + i18n.indith + '</th><th scope="col">' + i18n.indiper + '</th><th scope="col" class="valor">' + i18n.indival + '</th></tr></thead>' + tbody + '</table>'
						:
						""
						;
				},
				esquerra = function (json) {
					var
						/* versió text separat per comes
						litgeo=function(arr){
							var text=[];

							arr.forEach(function(e,i,a){
								var s=i18n.geo[e];
								text[i]=i ? s.charAt(0).toLowerCase() + s.slice(1) : s; //posem en minúscula si no és primer ítem
							});

							return text.join(", ");
						}*/
						litgeo = function (arr) {
							var text = "";

							arr.forEach(function (e, i, a) {
								text += (typeof i18n.geo[e] !== "undefined") ? "<li>" + i18n.geo[e] + "</li>" : "";
							});

							return "<ul>" + text + "</ul>";
						},
						litdata = function (d) {
							var data = d.split("-");

							switch (data.length) {
								case 2: return i18n.month[data[1] - 1] + " " + data[0];
								case 3: return Number(data[2]) + " " + i18n.monthprep[data[1] - 1] + " " + data[0];
								default: return d; /* o té long=1 ergo pq és any o pq no sabem que és */
							}
						},
						period = function (p) {
							return (i18n.period.hasOwnProperty(p)) ? i18n.period[p] : "—"; //no hauria de passar mdash
						},
						stat = json[0].stat[0],
						topic = json[0].topic,
						mono = stat.urls[0],
						cal = stat.cal,
						last = stat.last || mono.ref //1.0.26
						;

					$("#period").text(period(stat.period));
					var disponible = $("#disponible");
					if (!stat.live) {
						disponible.after('<dd class="nok">' + i18n.novig + '</dd>');
					}

					if (last && stat.first !== last) {
						disponible
							.addClass("interval")
							.html('<time class="inici">' + stat.first + '</time><strong> : </strong><time class="final">' + last + '</time>')
							;
					} else {
						disponible.html('<time>' + stat.first + '</time>');
					}

					$("#territ").html(litgeo(stat.geo));
					$("#tema").html('<a href="/tema/' + topic.id.toLowerCase() + IDESCAT.qlang + '">' + (topic.parent.id ? topic.parent.label : topic.label) + "</a>");

					/*rectificacions XMB20200602*/
					if (stat.corrections) {
						$("#territ").after('<dt>' + i18n.rect + '</dt><dd><time' + stat.corrections + '><a href="/rectificacions/?estad=' + id + IDESCAT.alang + '" title="' + i18n.recttitle + '">' +
							litdata(stat.corrections) + "</a></time></dd>");
					}

					/*propera*/
					if (stat.live && cal.next && cal.next.date) {
						var
							calnext = cal.next,
							anycal = calnext.date.split("-")[0],
							nextdesc = (calnext.desc ? calnext.desc : i18n.dades) + " " + calnext.ref,
							linkdatenext = '<a href="/cal/?id=' + id + '&amp;t=' + anycal + IDESCAT.alang + '" title="' + i18n.nextdatetitle + '">' + litdata(calnext.date) + '</a>',//FLM20181213
							datetimenext = calnext.date ? ' datetime="' + calnext.date + '"' : ""//FLM20181213
							;

						//FLM20181213
						$("#territ").after('<dt id="proper">' + i18n.next + '</dt><dd><time' + datetimenext + '>' + linkdatenext + "</time></dd>");

						$("#proper+dd").after("<dd>" + nextdesc + "</dd>");
					}

					/*darrera*/
					if (cal.curr) {
						var
							currdate = cal.curr.date,
							expiresdate = cal.curr.expires ? cal.curr.expires : "4000-04-16",
							nowdate = new Date().toISOString().substring(0, 10),
							currdesc = (cal.curr.desc ? cal.curr.desc : i18n.dades) + " " + cal.curr.ref,
							datacio = $(".Datacio time"),
							peuTime = (datacio.length === 1) ? new Date(datacio.attr("datetime")).getTime() : 0,
							currTime = new Date(currdate).getTime(),
							linkdatecurr = (cal.curr.id && expiresdate > nowdate) ? ('<a href="/novetats/?id=' + cal.curr.id + IDESCAT.alang + '" title="' + i18n.currdatetitle + '">' + litdata(cal.curr.date) + '</a>')
								: litdata(cal.curr.date),
							datetimecurr = currdate ? ' datetime="' + currdate + '"' : ""//FLM20181213
							;

						$("#territ").after('<dt id="darrer">' + i18n.curr + '</dt><dd><time' + datetimecurr + '>' +
							linkdatecurr + "</time></dd>");//FLM20181213
						$("#darrer+dd").after("<dd>" + currdesc + "</dd>");

						//XMB20180709 Actualitza datació XMB20181218: Adapto per a nou criteri datació: ja no currdate.replace(/-/g,".")
						if (peuTime < currTime) { //currdate no inclou mai temps, només dia, ergo podem simplement canviar "-" per "."
							var currarr = currdate.split("-");
							datacio.text(currarr[2] + "/" + currarr[1] + "/" + currarr[0]);
							datacio.attr("datetime", currdate);
						}
					}

					/* Els afegim en ordre invers: */

					/* Treball de camp: s'accepta string en blanc */
					if (typeof stat.field === "string") {
						//Botó
						$("dl.parell").after('<a id="camp" href="/dades/' + id + '/' + IDESCAT.qlang + '">' + i18n.field + ' ' + stat.field + '</a>');
						//Avís
						$.ajax({
							dataType: "json",
							url: "/dades/encurs/",
							data: {
								"f": "json",
								"id": id,
								"lang": IDESCAT.lang
							},
							success: function (data) {
								if (data && data.open === true) {
									$("main").children("div.aplec").prepend('<div id="miab" role="alert"><p>' + i18n.ecurs + '<a href="/dades/' + id + '/' + IDESCAT.qlang + '">' + i18n.ecursa + '</a>.</p></div>');
								}
							}
						});
					}
						
					/* PAAE */
					if ($.isArray(stat.plan) && stat.plan.length > 0) {
						var
							plan = stat.plan,
							paae = plan[0].paae, //Asumim totes actuacions són del mateix pec i paae
							pec = plan[0].pec,
							actuacions = plan.map(function (e) {
								var
									label = typeof e.label === "string" ? e.label.charAt(0).toUpperCase() + e.label.slice(1) : "",
									codi = e.id.slice(0, 2) + "&thinsp;" + e.id.slice(2, 4) + "&thinsp;" + e.id.slice(4, 6)
									;

								return '<a class="noseparable" title="' + label + '" href="/pec/' + e.pec + '/?id=' + e.id + '&amp;paae=' + e.paae + IDESCAT.alang + '">' + codi + '</a>';
							})
							;

						$("dl.parell").after('<p class="paae">' + i18n.paaeestad.replace("{CODI}", actuacions.join(" / ")).replace("{ANY}", paae) + '</p>');
					}

					/* Dades obertes 1.3.6 */
					if (stat.micro /* Microdades 1.3.12 */
						|| ($.isArray(stat.zip) && stat.zip.length > 0)
					) {
						$("dl.parell").after(
							'<div class="Apart desflota" id="opendata"><div class="cont"><h2>' +
							i18n.odata.titol + '</h2>' +
							($.isArray(stat.zip) && stat.zip.length > 0 ? '<p>' + i18n.odata.desc.replace("{CODI}", id) + '</p>' : '') +
							(stat.micro ? '<p>' + i18n.micro + '</p>' : '') +
							'</div><div class="img" aria-hidden="true"><img src="/dades/obertes/descarrega.png" alt=""></div></div>'
						);
					}

					/* Metodologia */
					if (mono.metod) {
						/* En principi ja hauria de venir informada metodologia, però per si un cas */
						if (!$("#Opcions").length) {
							var opcions = ($("#TitolComp").length) ? $("#TitolComp") : titol;
							opcions.after('<ul id="Opcions"><li class="metod"><a id="metod" href="' + amp(mono.metod) + '">' + i18n.metod + '</a></li></ul>');
						}
					}

					/* Indicadors si n'hi han */
					params = { estad: id, verbose: false, lang: IDESCAT.lang };
					if (priv) {
						params.priv = 1;
					}
					$.getJSON("https://api.idescat.cat/indicadors/v1/dades.json", params)
						.done(function (json) {
							if (json.indicadors.n !== "0") {
								$("#aldia").prepend(indic(json));
							}
						})
						/* Si fail no farem res (dissimulem)
						.fail(function(jqxhr, textStatus, error){
							var err = textStatus + ", " + error;
							//console.log( "Request Failed: " + err );
						})
						*/
						;

					/* Gràfic opcional */
					if (stat.chart) {
						$("#aldia").append('<div class="Apart"><figure class="embed ratio16x12"><iframe tabindex="-1" title="' + i18n.datavizIframeTitle + '" role="img" scrolling="no" allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" allowTransparency="true" src="' + amp(stat.chart) + '"></iframe></div>');
					}

					/* Taules destacades
					if(stat.featured){
						var lis="";
						stat.featured.forEach(function(e,i,a){
							lis+='<li><a href="'+amp(e.url)+'">'+e.label+'</a></li>';
						});
						$("#fitxa").append('<div id="taulesnov"><h2>'+i18n.featured+'</h2><ul>'+lis+'</ul></div>');
					}
					*/

					/* Taula opcional */
					if (stat.table &&
						window.location.hostname.indexOf("idescat") <= -1 //#FLM210701
					) {
						$("#fitxa").after('<div id="embed"></div>');

						incrustable({
							id: "embed",
							url: stat.table,
							n: 1, //de moment s'imposa (tb és el valor per defecte)
							tfoot: false //que és el valor per defecte
						});
					}
					/* fi Taula opcional */

					/* JME */
					/* Infografies */
					/*
					<style>
								#infografia h3 {
									margin: 0 0 2px 0;
								}
								#infografia a {
									display: block;
								}
								#Contingut div.Apart #infografia p {
									margin: -2px 0 0 0;
									font-size: 75%;
									line-height: 1;
								}
							</style>
							<div id="infografia">
								<h3>Infografia</h3>
								<a href="/dades/ecv/ecv2024ca.pdf" target="_blank" rel="noopener" title="PDF (S'obrirà una nova finestra)"><img src="/dades/ecv/ecv2024.png" alt="Infografia" /><p>ECV 2024</p></a>
							</div>
					*/

					/* JME20250221.Generem la infografia */
					if (stat.infografia) {
						params = { api: '', id: id, lang: IDESCAT.lang };
						$.get("/dades/infografies", params)
							.done(function (htmlinfografia) {
								$("#infografia").empty();
								$("#infografia").html(htmlinfografia);
							});
					}

				}; /* final esquerra()*/

			/* Sigles a títol */
			if(!$("#sigles").length){
				titol.html( '<abbr id="sigles">' + id.toUpperCase() + '</abbr><span class="guio"> &mdash; </span>' + titext );
			}

			plantilla='<div class="aplec">'+plantilla+"</div>";
			aplec.removeAttr("class"); //Treiem .aplec

			/* Filera (class parell innecessari) */
			start.after(plantilla);
			//<dl class="parell"></dl>

			$(".central")
				.html(aplec)
				.prepend('<div id="aldia"></div><div id="fitxa"></div>')
			;
			$("#fitxa").html( $(".central .Apart") );

			//No podem utilitzar variable aplec perquè contingut s'ha actualitzat
			$("main > div.aplec").append(barrasocial);

			/*crida ajax per recuperar continguts*/
			params={main: "true",type: "mono",stat: id,lang: IDESCAT.lang};
			if(priv){
				params.priv=1;
			}

			$.getJSON("/_T20Rest/rest/op", params)
				.done(function(json) {
					//Validar que és correcte resposta (array i no buit): si és objecte, length undefined, si és array buit length=0
					if(json.length){
						esquerra(json);
					}
				})
				/* Si fail no farem res (dissumulem)
				.fail(function(jqxhr, textStatus, error){ //PENDENT
					var err = textStatus + ", " + error;
					//console.log( "Request Failed: " + err );
				})
				*/
			;

			//Només s'executa si existeix #qcercaemex
			sug({
				input: "#qcercaemex",
				output: "#Sintesi",
				url: "https://api.idescat.cat/pob/v1/sug.json",
				filtre: "tipus",
				valid: [ {tipus: "ncars", valor: 2} ],
				mincar: 2
			});
		}
	}
}
