/*jshint esversion: 6*/

import alertARIA from "./alertaria.js";
import nooutline from "./nooutline.js";
import getNextAriaControlsId from "./getnextariacontrolsid.js";

export default function persiana(){
  alertARIA();//inicialitzem l'element d'avisos per l'accessibilitat
  if($(".Persiana").length){
    var
      imgp="/images/persiana",
      imgt="/images/plegartg.png",
      imgo="/images/desplegartg.png",
      pers=function(v,mm){mm.find("img").attr("src", imgp+v+".png");},
      estat=function(s,mm){mm.data("estat", s);},
      anima=function(e,o,p){
        var f=(o) ?
          function() {e.show(null, checkTots(p));} : function() {e.hide(null, checkTots(p));};
        e.animate({height: "toggle"}, 100, f);
      },
      ot=function(e,o){
        var i=(o) ? imgo : imgt;
        e.css("background-image","url("+i+")"); //IE6
      },
      checkTots=function(p){
        var
          h2=$(p).find("h3").length,
          h3=$(p).find("div.Cont").length,
          vh2=$(p).find("h3:visible").length,
          vh3=$(p).find("div.Cont:visible").length,
          mm=$(p).prev() //mesmenys
        ;
        if(h2===vh2){
          if(h3===vh3){
            pers(2,mm);
            estat(3,mm);
          }else{
            pers(1,mm);
            estat(1,mm);
          }
        }else{
          if(vh2===0){
            pers(0,mm);
            estat(0,mm);
          }
        }
      },
      updatePlegarEstendreMesMenys=function(element){
        var
          p=element.next(),
          div=$(p).find("div"),
          h2=$(p).find("h2"),
          h3=$(p).find("h3"),
          h2h3=$(p).find("h2,h3"),
          imgmesmenys=$(".mesmenys > img")
        ;
        switch(element.data("estat")){
          case 1:
            $(div).show();
            ot(h2h3, false);
            pers(2, element);
            estat(3, element);
            h2h3.attr("aria-expanded", "true");
            alertARIA(IDESCAT.msg.llista.desplegatmes);
            imgmesmenys.attr("aria-label", IDESCAT.msg.llista.menys);
          break;
          case 2:
            $(div).hide();
            ot(h2h3, true);
            pers(0, element);
            estat(0, element);
            h2h3.attr("aria-expanded", "false");
            alertARIA(IDESCAT.msg.llista.plegatmes);
            imgmesmenys.attr("aria-label", IDESCAT.msg.llista.mes);
          break;
          case 3:
            $(p).find("div.Cont").hide();
            ot(h2, false);
            ot(h3, true);
            pers(1, element);
            estat(2, element);
            h3.attr("aria-expanded", "false");
            alertARIA(IDESCAT.msg.llista.plegat);
            imgmesmenys.attr("aria-label", IDESCAT.msg.llista.menysmenys);
          break;
          default:
            $(p).find(">div").show();
            ot(h2, false);
            pers(1, element);
            estat(1, element);
            h2.attr("aria-expanded", "true");
            alertARIA(IDESCAT.msg.llista.desplegat);
            imgmesmenys.attr("aria-label", IDESCAT.msg.llista.mesmes);
        }
      },
      updatePlegarEstendreH2=function(h2, e, p){
        if (h2.css("background-image").indexOf(imgo)!==-1){ //show
          anima(e,true,p);
          ot(h2,false);
          h2.attr("aria-expanded", "true");
        }else{ //hide
          anima(e,false,p);
          ot(h2,true);
          h2.attr("aria-expanded", "false");
        }
      },
      updatePlegarEstendreH3=function(h3, e, p){
        if (h3.css("background-image").indexOf(imgo)!==-1){ //show
          anima(e,true,p);
          ot(h3,false);
          h3.attr("aria-expanded", "true");
        }else{ //hide
          anima(e,false,p);
          ot(h3,true);
          h3.attr("aria-expanded", "false");
        }
      }
    ;

    //Inicialitzar múltiples .Persiana
    $(".Persiana").before("<div class='mesmenys'><img alt='+/−' title='"+
      IDESCAT.msg.plegarTbody+"/"+IDESCAT.msg.estendreTbody+"' src='"+imgp+"0.png'/></div>");
    estat(0,$(".mesmenys"));

    //Plega/desplega un apartat
    $(".Persiana h2").each(function(index, element){
      var
        h2=$(element),
        e=h2.next("div"),
        p=h2.parent(),
        idariacontrols=getNextAriaControlsId()
      ;
      nooutline(h2);

      //Accesibilitat
      h2
        .attr("tabindex","0")
        .attr("role","button")
        .attr("aria-expanded", "false")
        .attr("aria-controls", idariacontrols)
      ;
      e
        .attr("id", idariacontrols)
      ;

      h2
        .click(function(){
          updatePlegarEstendreH2(h2, e, p);
       })
       .keyup(function(evt){
         if(evt.key==="Enter"){
            updatePlegarEstendreH2(h2, e, p);
         }
       })
    ;
  });

  $(".Persiana h3").each(function(index, element){
    var
      h3=$(this),
      e=h3.next(".Cont"),
      p=h3.closest(".Persiana"),
      idariacontrols=getNextAriaControlsId()
    ;
    nooutline(h3);

    //Accesibilitat
    h3
      .attr("tabindex","0")
      .attr("role","button")
      .attr("aria-expanded", "false")
      .attr("aria-controls", idariacontrols)
    ;
    e
      .attr("id", idariacontrols)
    ;

    h3
      .click(function(){
        updatePlegarEstendreH3(h3, e, p);
      })
      .keyup(function(evt){
       if(evt.key==="Enter"){
         updatePlegarEstendreH3(h3, e, p);
       }
      })
    ;
   });
    //Accessibilitat
    var mesmenys = $(".mesmenys"),
      imgmesmenys = mesmenys.children("img")
    ;
    imgmesmenys
      .attr("tabindex", "0")
      .attr("role", "button")
      .attr("aria-label", IDESCAT.msg.llista.mes)
    ;
    nooutline(mesmenys.children("img"));

    //Plega/desplega els apartats/subapartats
    //0=tot tancat; 1=apartats oberts venint de 0; 2=apartats oberts venint de 3; 3=tot obert
    mesmenys
      .click(function(){ //XMB danger: mesmenys no està dins de .Persiana, ergo no discriminable
        updatePlegarEstendreMesMenys($(this));
      })
      .keyup(function(e){
        if(e.key==="Enter"){
          updatePlegarEstendreMesMenys($(this));
        }
      })
    ;
  }
}//persiana()
