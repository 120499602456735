/*jshint esversion: 6*/

//cridada per extensible.js i sortejable.js
export default function trvincle(oTbody){
	var elements=[];

	if(oTbody!==null){
		elements=$(oTbody).find('tr th[scope!="col"],tr td').parent();
	}else{
		elements=$('table.Interactiva tbody tr th[scope!="col"], table.Interactiva tbody tr td').parent();
	}

	$(elements).each(function(){
		if(!$(this).parent().is("tbody.Extensio")){
			if($(this).find("a").length){
				var title=$(this).find("a:first").attr("title");
				if(title!==null && title!=="" && $(this).attr("title")===""){
					$(this).attr("title",title);
				}
				$(this).css("cursor", "pointer").click(function(){
					//Interactiva assumeix 1 enllac per linia
					window.location=$(this).find("a:eq(0)").attr("href");
				}).find("a").addClass("JS");
			}else{
				$(this).closest("tr").addClass("sensevincle");
			}
		}
	});
}
