/*jshint esversion: 6*/

import getNextAriaControlsId from "./getnextariacontrolsid.js";
import tooltip from "./tooltip.js";

export default function multigranular(){
	if($("div.Multigranular").length){
		var
			fieldsets=$("div.Multigranular fieldset"), //llista de <fieldsets> dins de div.Multigranular
			ariaLabelTable=($("select.Multigranular").attr("aria-label") ? ' aria-label="'+$("select.Multigranular").attr("aria-label")+'"' : ""),
			llegirFieldset=function(fs){
				var
					divOpcional=$(fs).find("div.Opcional"),
					select=divOpcional.children("select.Multigranular").clone(), //<select> es clona perque més endavant es destrueix
					ariaConstrolsId=getNextAriaControlsId(),
					s={value:select.val(), name:select.attr("name")},
					opt=select.children(), //llista de <option> o <optgroup> que hi ha dins del select
					ul="",
					liSel=[], //associa un select al <li> i-èsim
					liChecked=false, //indica si hi ha algun radio input, de dins del <li>, que estigui checked
					afegirLi=function(i, html, checked, ajuda){ //afegeix <li>..</li> al <ul>
						var text=(ajuda===null) ? "" : ' (<span class="ajuda" title="'+ajuda+'">?</span>)';
						ul+="<li><label><input type='radio' aria-controls='"+ariaConstrolsId+"' value='"+i+"'"+((checked) ? " checked='checked' " : "")+
							"/> "+html+"</label>"+text+"</li>";
					},
					//afegeix <option>..</option> a l'array de selects associats a un <li>
					afegirOption=function(i, html, selected, value, dataBy){
						liSel[i]+="<option value='"+value+"'"+
							(selected ? " selected='selected'": "")+(dataBy ? " data-by='true'" : "")+">"+html+"</option>";
					},
					//AMG20231129
					afegirListenerChange=function(){
						divOpcional.find('.Multigranular').on('change', function(){
							if(typeof $(this).find("option:selected").data('by') === 'undefined') {
								$(this).parents('fieldset').find('span[role=alert]').remove();
							} else {
								if(!$(this).parents('fieldset').find('span[role=alert]').length) {
									$(this).parents('fieldset').append('<span role="alert">'+IDESCAT.msg.cancelarSerie+'</span>');
								}
							}
						});
						if (typeof divOpcional.find("option:first-child").data('by') !== 'undefined' && divOpcional.find("option:first-child").prop('selected')) {
						 	if(!divOpcional.parents('fieldset').find('span[role=alert]').length) {
								divOpcional.parents('fieldset').append('<span role="alert">'+IDESCAT.msg.cancelarSerie+'</span>');
							}
						} else {
							divOpcional.parents('fieldset').find('span[role=alert]').remove();
						}
					},
					normalitzar=function(s) {
						var s1 = 'ÀÁÈÉÌÍÒÓÙÚ';
						var s2 = 'AAEEIIOOUU';
						for (var i = 0; i < s1.length; i++) s = s.replace(new RegExp(s1.charAt(i), 'g'), s2.charAt(i));
						return s;
					},
					ordenacioAlfabetica=function(optg){
						var result="",
							startl="",
							c="",
							optSelected = false,
							selectedAttr = "",
							dataBy = ""
						;

						optg.each(function(index, o){
							if(o.tagName==='OPTION'){
								c=normalitzar(o.text.substr(0, 1));
								// AMG20231129 (la 2a condició només)
								if (c !== startl && typeof $(o).data('by') === 'undefined'){
									if (startl){
										result += '</optgroup>';
									}
									result += "<optgroup label='"+c+"'>";
									startl=c;
								}
								selectedAttr = $(o).attr("selected") === "selected" ? " selected='selected'" : "";
								dataBy = typeof $(o).data("by") !== "undefined" ? " data-by='true'" : "";
								optSelected = optSelected || selectedAttr != "";
								result += "<option value='" + o.value + "'" + selectedAttr+dataBy+">"+o.text+"</option>\n";
							}
						});
						result += '</optgroup>';
						return {"optSelected": optSelected, "optg": result};
					}
				;

				divOpcional.html(""); //buidar div.Opcional

				//per cada option dins del select, comprovar si es de tipus option o de tipus optgroup
				opt.each(function(i){
					var 
						r, ajuda, optionList, attrSel,
						selected, //indica si dins del optgroup actual hi ha algun option seleccionat que s'hagi de tindre en compte
						algunOptSel=false, //indica si hi ha algun <option> amb atribut selected="selected"
						dataBy //indica si aquella option té el paràmetre data-by
					;

					//liSel[i]=null; //null indica que es tracta d'un optgroup i que, per tant, el <li> i-èsim no té cap select associat
					if(this.tagName==="OPTION"){ //this.tagName --> option | opt
						attrSel=$(this).attr("selected");
						liChecked=(attrSel==="selected" || attrSel);
						dataBy = typeof $(this).data("by") !== "undefined";
						afegirLi(i, this.innerHTML, liChecked, null);
						afegirOption(i, this.innerHTML, true, this.value, dataBy);

						divOpcional.html("<select class='Multigranular' name='"+s.name+"' id='"+ariaConstrolsId+"'"+ariaLabelTable+"><option "+liSel[i]+"</select>");
					}else{ //opt
						ajuda=( $(this).data("ajuda") ) ? $(this).data("ajuda") : null;
						optionList = $(this).children("option");
						liSel[i]="";
						if(optionList.length > 20){
							r = ordenacioAlfabetica($(this.innerHTML));
							algunOptSel=r.optSelected;
							liSel[i]+= r.optg;
						}else{
							optionList.each(function(){
								var attrSel=$(this).attr("selected");
								selected=(!liChecked && (attrSel==="selected" || attrSel)); //en IE7, l'atribut selected retorna un boleà
								dataBy = typeof $(this).data("by") !== "undefined";
								algunOptSel= algunOptSel || selected;

								afegirOption(i, this.innerHTML, selected, this.value, dataBy);
							});
						}
						afegirLi(i, this.label, algunOptSel, ajuda);
						if(algunOptSel){ //si hi ha alguna opció seleccionada, es mostra el <select> corresponen
							divOpcional.html("<select class='Multigranular' name='"+s.name+"' id='"+ariaConstrolsId+"'"+ariaLabelTable+">"+liSel[i]+"</select>");//FLM20190722
							afegirListenerChange();
						}
					}
				});

				//insertar <ul>..</ul> abans del div.Opcional
				divOpcional.before("<ul class='Multigranular'>"+ul+"</ul>");
				var inputs=$(fs).find("ul.Multigranular input"); //llista de radio inputs

				//al fer click a un radio input, al div.Opcional es mostrarà un select associat
				$(inputs).on('click', function(){
					var
						v=liSel[this.value], //<select> associat a aquesta opció
						/*XMB20220106 Ja no tractem Catalunya diferent des de v1.3.15. Deia:
						htmlSel=(v.indexOf("<option")>-1) ?
							"<select class='Multigranular' name='"+s.name+"' id='"+ariaConstrolsId+"'"+ariaLabelTable+">"+v+"</select>"
							:
							"<input type='hidden' value='"+v+"' name="+s.name+" class='Multigranular'/>"
						*/
						htmlSel="<select class='Multigranular' name='"+s.name+"' id='"+ariaConstrolsId+"'"+ariaLabelTable+">"+v+"</select>"
					;
					divOpcional.html(htmlSel); //actualitzar el contingut de div.Opcional
					afegirListenerChange();
					//idescat.js 1.4.4
					inputs.prop("checked",false);
					$(this).prop("checked",true);
					/*
					Fins a 1.4.3 deia:
					inputs.attr("checked",false);
					this.checked=true; //IE7
					*/
				});
			}
		;
		//llegir tots els fieldsets dins dels ApartD.Multigranular
		fieldsets.each(function(){
			llegirFieldset(this);
		});
		//Afegir tooltip
		tooltip(".ajuda");
	}
}//multigranular()
