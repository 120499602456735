/*jshint esversion: 6*/

//afegeix una funcionalitat a la llista de funcionalitats fetes servir
export function addFunc(act){
  var actives=$("html").data("actives") || "";
  $("html").data("actives", actives+"["+act+"]");
}

//indica si la funcionalitat 'nomFunc' s'està fent servir
export function isFunc(nomFunc){
  var actives =$("html").data("actives");
  return (actives && actives.indexOf("["+nomFunc+"]")!==-1);
}
