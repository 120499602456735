/*jshint esversion: 6*/

import nooutline from "./nooutline.js";
import GAClic from "./gaclic.js";

/* AMG20230118 Adaptada la comprovació del data-api al nou format amb un únic string. */

export default function download(paramURL){
	/*XMB20160511 #Opcions a.download és per marcació antiga sense ul; #Opcions .download a és la nova */
	var
		download = $("#download"),
		vinclesDown=$("#Opcions .download a, #Opcions a.download, .Avis a.download"),
		opdown=$("#Opcions .download"),
		opdowna=opdown.children("a"),
		llistaOpcionsTabIndex=function(index){
			opdown.siblings("li").children("a").attr("tabindex", index);
		},
		tancar = function(){
			download.hide();
			opdown.removeClass("sel");
			llistaOpcionsTabIndex("0");
			opdowna.attr("aria-expanded", "false");
		},
		configuraTancar = function() {
			let creuTancar = download.children("strong");
			creuTancar
				.click(tancar)
				.on("keyup", function(evt){
					if(evt.key==="Enter"){
						tancar();
					}
				})
			;
			nooutline(creuTancar);
		}
	;

	if (typeof paramURL === "undefined") {
		paramURL = {
			api: null,
			id: null
		};
	}

	vinclesDown.prop("onclick", null).off("click");
	vinclesDown.click(function(){
		if($("#download").length){ //ja s'ha construit l'opció de descàrrega
			download=$("#download");
			if(download.css("display") === "none"){
				download.show();
				opdown.addClass("sel");
				llistaOpcionsTabIndex("-1");
			}else{
				download.hide();
				opdown.removeClass("sel");
				llistaOpcionsTabIndex("0");
			}
		}else{ //cal construir-lo
			const
				useAPI = function() {
					/*1.5.6: Eliminem aquest filtre:
					if (window.location.hostname.indexOf("idescat") !== -1 && //TODO Eliminar el dia que destapem a www nova descàrrega estadístiques CAVA
						window.location.href.indexOf("&priv=__jsonstat__") === -1
					) {
						return null;
					}
					*/
					if (
						typeof document.createElement("a").download === "undefined" || // Això valida que el navegador accepti download
						typeof document.getElementsByTagName("body")[0].click !== "function" // Això valida que el click existeix
					) {
						return null;
					}
					// Cas que ens vingui la URL com a parametre
					let apiurl = paramURL.api;
					/* Potser l'ordre hauria de ser el contrari: si ve com a paràmetre mana sobre data-api */
					if (vinclesDown.data("api")) {
						apiurl = vinclesDown.data("api");
					}
					return typeof apiurl === "string" ? apiurl : null;
				},
				apidata = useAPI()
			;
				
			////API 0.0.39 XMB20180702
			// 
			if( apidata !== null ){
				/* carreguem CSS de nou download (amb API) dinàmicament */
				$("head").append('<link rel="stylesheet" type="text/css" media="all" href="/estils/down.css"/>');

				/* carreguem JS de nou download (amb API) dinàmicament */
				const 
					resource = document.createElement("script"),
					script = document.getElementsByTagName("script")[0]
				;
				resource.async = false;
				resource.src = "/commonScripts/down.js";
				resource.setAttribute("data-url", apidata || "");
				script.parentNode.insertBefore(resource, script);
				/* -------------------------------------------------- */
			} else {
				const 
					vincle = vinclesDown.attr("href"),
					attr=(IDESCAT.lang!=='en') ? [vincle,'checked="checked"', '']:[vincle,'','checked="checked"'],
					html=
						'<div id="download" class="nocava">'+
							'<strong title="'+IDESCAT.msg.tancar+'" tabindex="0" role="button" aria-label="'+IDESCAT.msg.tancar+'">&times;</strong>'+
							'<div class="csv">'+
								'<p>'+IDESCAT.msg.downd+'</p>'+
								'<fieldset>'+
									'<legend>'+IDESCAT.msg.downf+'</legend>'+
									'<div class="opcions">'+
										'<label><input type="radio" name="f" value="csv" '+attr[2]+'/>'+IDESCAT.msg.coma+'</label>'+
										'<label><input type="radio" name="f" value="ssv" '+attr[1]+'/>'+IDESCAT.msg.punticoma+'</label>'+
									'</div>'+
								'</fieldset>'+
								'<a href="'+attr[0]+'">'+IDESCAT.msg.downb+'</a>'+
							'</div>'+
							(paramURL.id ? '<p>'+IDESCAT.msg.seccioCava
														.replace('$1', '<a href="/estad/'+paramURL.id+(IDESCAT.lang !== "ca" ? IDESCAT.qlang : '')+'">')
														.replace('$2', '</a>')
														.replace('$3', paramURL.id.toUpperCase())+'</p>' 
											: '')+
						'</div>'
				;

				//insertar opcions de descàrrega
				$(html).insertAfter($("#Opcions"));
				download = $("#download");
				opdown.addClass("sel");

				//afegir event click als checkbox
				download.find(".csv input[type=radio]").click(function(){
					download.find(".csv a").attr("href",
						vincle.replace(/([\(&|?)])f=(.[^\(&|#)]*)/, "$1f="+$(this).val()));
				});

				//click a 'x'
				configuraTancar();
				
				//analítica web
				download.children(".csv a").click(function(){
					GAClic(this);
				});
				llistaOpcionsTabIndex("-1");
			}
		}

		opdowna.attr("aria-expanded", opdown.hasClass("sel"));
		opdowna.attr("aria-controls", "download");
		return false;
	});

	//Accessibilitat
	opdowna
		.attr("role","button")
		.attr("aria-expanded", opdown.hasClass("sel"))
	;
	nooutline("#Opcions .download a"); /*XMB20200114*/
}