/*jshint esversion: 6*/

import validainput from "./validainput.js";
import autocomplete from "./autocomplete.js";

//activa suggest sobre un input (es crida des del peu)
//XMB20190621 output recupera el seu sentit original que era el lloc on s'incrusta possible missatge (i no on s'incrusta el resuñtat del suggeriment). El lloc ara es diu "on"

//XMB20190621 Propietats p

//						input			Selector de l'element que cal segrestar (requerit)
//						url			Adreça del servei que proveeix els resultats (requerit)

//						valid			Regles de validació del valor introduïtemp
//						mincar		Nombre mínim cars que disparen suggest
//						filtre		Element del formulari (data-) on apart de dir-li una Q, s'ha de filtrar per alguna cosa
//						strict
//						output		Selector de l'element on s'escriu missatges si "valid" no valida
//						on				On incrustrar la llista resultant

export default function sug(p){
	//AMG20231102
	if(p.on && $(p.on).length === 0){
		p.on = null;
	}
	if($("div#Titular").length && $("div#Titular").find(p.input).length && !p.on){
		p.on="div#Titular";
	}

	if(typeof p.input==="undefined" || typeof p.url==="undefined"){
		return;
	}

	var input=(typeof p.input==="string") ? $(p.input) : p.input;

	//XMB Segurament això ja no cal perquè autocomplete s'insereix dins de #Titular i s'estila en funció d'això
	if(input.closest("#Micro").length){
		$("body").addClass("micro");
	}

	input.attr("spellcheck", "false");

	validainput(p);
	autocomplete({
		element: input,
		url: p.url,
		mincar: p.mincar,
		filtre: p.filtre,
		on: p.on || null, //lloc on s'insereix: per defecte body
		submit: (p.submit===true), //XMB20200531
		mode: p.mode || "normal",
		select: p.select || null
	});
}
