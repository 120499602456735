/*jshint esversion: 6*/

export default function alertARIA(msg){
	var div=$("#Contingut div.WAIocult[role=alert]");

	if(div.length===0){
		$("#Contingut").append('<div class="WAIocult" role="alert"></div>');
		div=$("#Contingut div.WAIocult[role=alert]");
	}
	if(typeof msg==="string"){
		div.text(msg);
	}
}
