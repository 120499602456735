/*jshint esversion: 6*/

//Nombre d'execucions d'IDESCAT.rectificacions() en una pàgina
var nombreExec=(function () {
	var n=0;
	return function () {
		n++;
		return n;
	};
})();


export default function rectificacions(dades){
	const
		server="https://api.idescat.cat/rectificacions/v1/",
		apartnum=$(".ApartNum, table.hover"),
    	myself=window.location.href
	;

	if(apartnum.length){
		const qlang=(IDESCAT.lang==="ca") ? "" : "?lang=" + IDESCAT.lang;

		switch(IDESCAT.lang){
			case "es":
				IDESCAT.msg.rectificacions={
					button: "Tabla rectificada el",
					caption: "Historial de rectificaciones de esta tabla",
					codi: "Código",
					desc: "Descripción",
					datapub: "Fecha publ.",
					datarect: "Fecha rect.",
					millora: "Mejora"
				};
			break;
			case "en":
				IDESCAT.msg.rectificacions={
					button: "Table corrected on",
					caption: "Corrections history to this table",
					codi: "Code",
					desc: "Description",
					datapub: "Pub. date",
					datarect: "Corr. date",
					millora: "Improvement"
				};
			break;
			default:
				IDESCAT.msg.rectificacions={
					button: "Taula rectificada el",
					caption: "Historial de rectificacions d'aquesta taula",
					codi: "Codi",
					desc: "Descripció",
					datapub: "Data publ.",
					datarect: "Data rect.",
					millora: "Millora"
				};
		}

		const
			msg=IDESCAT.msg.rectificacions,
			addCSS=function(n){
				if(n===1){
					$("head").append('<link rel="stylesheet" type="text/css" media="all" href="/estils/tblrect.css"/>');
				}
			},
			fmtdata=function(dia){
				const d=dia.split("-");
				return `${d[2]}/${d[1]}/${d[0]}`;
			},
			fesHTML=function(json, i, selector){
				let
					tr="",
					data=""
				;

				json.forEach((rect,j)=>{
					const
						corrmill=!rect.improv ? "" : msg.millora + ": ",
						updated=fmtdata(rect.updated)
					;
					tr+=`<tr><th><a href="/rectificacions/${rect.id}${qlang}">${rect.id}</a></th>`+
							`<td>${corrmill}${rect.content}</td><td>${fmtdata(rect.published)}</td><td>${updated}</td></tr>`;

					if(j===0){
						data=updated;
					}
				});

				const
					table=`<div class="rectificacions" data-count="${i}">
								<button aria-expanded="false" aria-controls="ARIArectificacions${i}" data-count="${i}">
									<span aria-hidden="true">${msg.button} ${data}</span>
									<span aria-label="${msg.button} ${data}" class="fletxa dsc"></span>
								</button>
								<div id="ARIArectificacions${i}">
									<table class="ApartNum"><caption>${msg.caption}</caption>
										<thead><tr><th>${msg.codi}</th><th>${msg.desc}</th><th>${msg.datapub}</th><th>${msg.datarect}</th></tr></thead>
										<tbody>${tr}</tbody>
									</table>
								</div>
							</div>`
				;

				if(selector){
					$(selector).html(table);
				}else{
					apartnum.eq(i).after(table);
				}
			},
			assignaEsdeveniments=function() {
				//events
				IDESCAT.nooutline(".rectificacions button");
				$(".rectificacions button").click(function(){
					const
						count=$(this).data("count"),
						cont=$(".rectificacions[data-count="+count+"] div")
					;
					if(cont.css("display")==="none"){
						cont.show();
						$(this).attr("aria-expanded", "true");
					}else{
						cont.hide();
						$(this).attr("aria-expanded", "false");
					}
				});
			},
			processa=function(json){
				if(json && json.length){
					addCSS(1);

					apartnum.each(function(i){
						if(json[i] && json[i].length){
							fesHTML(json[i], i);			
						}
					});

					assignaEsdeveniments();
				}
			},
			atom2rect=function(atom){
				if(atom.hasOwnProperty("feed") && atom.feed.hasOwnProperty("entry")){
					const 
						entry=Array.isArray(atom.feed.entry) ? atom.feed.entry : [atom.feed.entry],
						arr=[]
					;
	
					entry.forEach(r=>{
						arr.push({
							id: r.id.replace("tag:idescat.cat,2010:rectificacions/",""),
							published: r.published,
							updated: r.updated,
							improv: r.category.length>3, //criteri massa simple? Ara per ara rectif estan classificades sempre per tema, producte, estadística. Si hi ha 4 categories és que s'ha assignat millora.
							content: r.content.div.content
	
						});
					});
	
					return arr;
				}
	
				return null;
			}	
		;

		//Si ens passen un array, no fem Ajax
		if(typeof dades==="object"){
			if(Array.isArray(dades)){
				processa(dades);
			}else if(dades.hasOwnProperty("api") && dades.hasOwnProperty("selector")){
				$.getJSON(server+dades.api, atom=>{ //dades.api serà la part no comuna a totes les crides de l'API de rectificacions
					const json=atom2rect(atom);
					if(json){
						addCSS(nombreExec());
						fesHTML(json, 0, dades.selector);
						assignaEsdeveniments();
					}
				});
			}else{
				//no fer res. Argument invàlid
				return false;
			}
		}else{
			$.getJSON(server+"web.json?url="+encodeURIComponent(myself), json=>{
				processa(json);
			});
		}
	}
}
