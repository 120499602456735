/*jshint esversion: 6*/

import nooutline from "./nooutline.js";

export default function grincles(){
	if($(".grincles").length){
		$(".grincles button").click(function(){
			const idElem=$("#"+$(this).attr("aria-controls"));

		  if($(this).attr("aria-expanded")==="false"){
		    idElem.show();
		    $(this).attr("aria-expanded", "true");
		  }else{
		    idElem.hide();
		    $(this).attr("aria-expanded", "false");
		  }
		});

		nooutline(".grincles button");
	}
}
