/*jshint esversion: 6*/
import destaca from "./destaca.js";

export default function autocomplete(p) {
  var
    el = (typeof p.element === "string") ? $(p.element) : p.element,
    url = p.url,
    min = p.mincar,
    q = p.q || "q",
    filtre = p.filtre || null,
    elemAutocomplete = null,
    mode=p.mode ? p.mode : "normal"// "strict" | "multi" | "normal"
	;

  if (el.length) { //comprovar que l'element existeix
    var
      //funció "response" comuna
			resp=function(request, response, tags){
        var destacats = destaca({mode: mode, haystack: tags, needle: request.term}),
          arrayResult = [];
        tags.forEach(function(item, index){
          arrayResult.push({ value : item, label :  destacats[index]});
        });
				response(arrayResult);
        //background-color diferent per files senars
        $(".ui-autocomplete .ui-menu-item:odd").addClass("odd");
      },
      //si url és un string, llavors source fa una crida en ajax per recuperar el suggest
      source = (typeof url === "string") ?
      function(request, response) {
        $.ajax({
          type: "GET",
          url: url +
            ((url.indexOf("?") < 0 && url.indexOf("&") < 0) ? "?" : "&") +
            q + "=" + request.term +
            ((filtre !== null) ? "&" + filtre + "=" + el.data(filtre) : ""),
          dataType: (jQuery.support.cors) ? "json" : "jsonp",
          success: function(data) {
            resp(request, response, data[1]);
          }
        });
      } :
      //en cas contrari (url és un array de strings),
      //source es limita a utilitzar aquest array per fer el suggest
      function(request, response) {
        resp(request, response, url);
      };

    var options = {
      appendTo: p.on || null, //per defecte body
      autoFocus: true,
      minLength: min,
      source: source,
      position: {
        my: "left top",
        at: "left bottom",
        collision: "flipfit"
      },
      classes: {
        "ui-autocomplete": "ui-v1121"
      }
    };

    if (p.submit) {
      options.select = function(event, ui) {
        $(this).val(ui.item.value);
        $(this).closest("form").submit();
      };
    }

    if (p.select) {
      options.select = p.select;
    }

    el.autocomplete(options);
    elemAutocomplete = el.data("ui-autocomplete") || el.data("autocomplete");
    if (elemAutocomplete) {
      elemAutocomplete._renderItem = function(ul, item) {
        // only change here was to replace .text() with .html()
        return $("<li></li>")
          .data("item.autocomplete", item)
          .append($("<div></div>").html(item.label))
          .appendTo(ul);
      };
    }
  }
} //autocomplete)()
