/*jshint esversion: 6*/

import agrupaclassif from "./agrupaclassif.js";
import nooutline from "./nooutline.js";
import {addFunc, isFunc} from "./addisfunc.js";
import tooltip from "./tooltip.js";
import trvincle from "./trvincle.js";

//No es contempla l'escenari que tinguem més d'una taula del tipus 'Sortejable' a la mateixa pàgina
export default function sortejable(){
	var sortTable = $("table.Interactiva, table.Classif");
	if(sortTable.length){
		var
			afegirAccessibilitat=function(){
				//Accessibilitat
				$('table.Interactiva th[scope="col"] a, table.Interactiva th[scope="colgroup"] a, table.Classif th[scope="col"] a, table.Classif th[scope="colgroup"] a').each(function(index, element){
					var
						a = $(element),
						tr = a.parent().parent()
					;

					if(a.hasClass("agrupa")){
						a.attr({
							"role": "presentation",
							"tabindex": "-1"
						});

						tr
							.attr({
								"role": "button",
								"tabindex": "0",
								"aria-label": a.attr("title")
							})
							.on("click keyup", function(event){
								if((event.type==="keyup" && event.key==="Enter") || event.type==="click"){
									window.location.href = a.attr("href");
								}
							})
							.css("cursor", "pointer")
						;
					}
					nooutline(a);
				});
			},
			capsSortejables=function(){
				$('table.Interactiva th[scope="col"] a, table.Interactiva th[scope="colgroup"] a, table.Classif th[scope="col"] a, table.Classif th[scope="colgroup"] a').click(function(){
					var taula=$(this).closest("table");

					$.get($(this).attr("href")+"&m=a", function(data){
						if(data.indexOf("<thead")>-1){
							$(taula).find("thead, tfoot").remove();
						}
						$(taula).find("tbody").remove();
						$(taula).append(data);
						afegirAccessibilitat();
						capsSortejables();
						trvincle(null);
						/*	si és una taula de tipus Classif agrupable i sortejable, llavors cal
							inicialitzar els plegats i refrescar els events */
						if($(taula).hasClass("Classif") && $(taula).find("tbody tr[data-parent]").length){
							agrupaclassif(taula);
						}
						/* Combinacio ApartNum agrupable i sortejable NO ESTA CONTEMPLADA.
							Caldria definir un agrupaapartnum a agrupa.js */

						//Extensible combinada amb sortejable. Segrestem vincles en cas afirmatiu
						if(isFunc("extensible")){
							$(taula).trigger("changeData");
						}
						if(isFunc("desplegable")){
							$(".Desplegable").trigger("update");
						}
					});
					return false;
				});
				afegirAccessibilitat();
			}
		;

		//if(typeof(inhibidorSortejable)==="undefined"){
			trvincle(null);
			capsSortejables();
		//}
		addFunc("sortejable");
		//Afegir tooltip
		tooltip("abbr");
		//Eliminem atributs role="grid" i aria-readonly="true" en el cas que ho rebem
		if(sortTable.attr("role")){
			sortTable.removeAttr("role aria-readonly");
		}
	}
	//
}//sortejable()
