/*jshint esversion: 6*/

export default function dataviz(obj) {
		const 
			node = obj && obj.filter && obj.filter[0] ==="n" ? obj.filter[1] : "",
			viz = $("#Dataviz" + node)
		;
		if (viz.length) {
			var oembed = $("link[type='application/json+oembed']").attr("href"),
					frag = oembed.split("/"),
					api = "//" + frag[2],
					encurl = frag[frag.length - 1].split("url=")[1]
			;

			if (oembed && encurl) {
				var 
					dataviz_obert = false,
					dataviz_recuperat = false,
					getFiltre=function(delim){
						return (node ? delim+`filter=n/${node}` : "");
					},
					url = oembed.replace(/http:/, "") + "&rc=200&callback=?" + getFiltre("&"),
					//Eliminació de http: temporalment: no hauria de venir o venir un protocol coherent amb el de la pàgina
					incrustar = function incrustar(state) {
						var img = state !== "unhidable" ? '<img tabindex="0" class="ico" role="img" src="/images/graf.png" alt="' + IDESCAT.msg.datavizAlt + '" title="' + IDESCAT.msg.datavizTitle + '" />' : ""; //XMB20191108

						viz.html(img + '<div class="viz"><div class="dataviz"><div class="Cont WAIocult embed ratio16x12"><iframe title="' + IDESCAT.msg.datavizIframeTitle + '" role="presentation" allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" src="' + api + '/g.ifr?url=' + encurl + getFiltre("&amp;") +'&amp;lang=' + IDESCAT.lang + '&amp;hid=1&amp;auto=1"></iframe></div></div></div>'); //FLM20181213

						var 
							cont = viz.find(".Cont"),
							//Només un Dataviz per pàgina
							iframeElement = $("#Dataviz iframe") //Atributs accessibilitat FLM20190603
						;

						viz.attr("aria-hidden", "true");
						cont.attr("aria-hidden", "true");

						if (state !== "unhidable") {
							var 
								ico = $("#Dataviz .ico"),
								show = function show() {
									cont.hide();
									cont.removeClass("WAIocult"); //ELIMINEM ANIMACIO inicial 0.0.9 pq és asínc i dóna problemes en còmput dimensions a iPad cont.animate({height: "toggle"}, 100, function() {

									cont.show(); //ELIMINEM ANIMACIO 0.0.9});
								},
								open = function open() {
									if (!dataviz_recuperat) {
										show(); //refresquem un cop visible o no sabrà dimensions almenys en mòbils

										if ("onorientationchange" in window) {
											//it's probably a mobile...
											$(iframeElement).attr("src", $(iframeElement).attr("src"));
										}

										dataviz_recuperat = true;
									} else {
										//Afegit a 0.0.9 refresquem per si ha canviat orientació de mòbil
										if ("onorientationchange" in window) {
											//it's probably a mobile...
											$(iframeElement).attr("src", $(iframeElement).attr("src"));
										} //ELIMINEM ANIMACIO 0.0.9 cont.animate({height: "toggle"}, 100, function() {

										cont.show(); //ELIMINEM ANIMACIO 0.0.9 });
									}

									dataviz_obert = true;
								},
								close = function close() {
									dataviz_obert = false;
									cont.animate({
										height: "toggle"
									}, 100, function () {
										cont.hide();
									});
								},
								clickFunc = function clickFunc(enter) {
									if (dataviz_obert) {
										//tanquem
										close();
									} else {
										//obrim
										open();
									}

									if (!enter) {
										showOutline(false);
									}
								},
								showOutline = function showOutline(show) {
									//FLM20190603
									if (show) {
										ico.removeClass("nooutline");
									} else {
										ico.addClass("nooutline");
									}
								}
							;

							if (state === "open") {
								open();
							} //Ico de Dataviz

							ico.click(clickFunc).keyup(function (evt) {
								if (evt.key === "Enter") {
									clickFunc("enter");
								}
							});
							
							ico.hover(function () {
								showOutline(false);
								ico.attr("src", "/images/grafhover.png");
							}, function () {
								ico.attr("src", "/images/graf.png");
							});

							ico.blur( //FLM20190603
								function () {
									showOutline(true);
								})
							;
						} else {
							//unhidable
							cont.hide();
							cont.removeClass("WAIocult");
							cont.show();
						}
					}
				;

				/*XMB20160603 Actualitzat el comentari per fer-ho aclaridor:
					Comprovem que hi hagi l'element on incrustarem el gràfic.
					I que API realment retorni que hi ha incrustació associada
					tot i que no tenim en compte valor retornat, només si OK o no.
				*/
				if (viz.length === 1) {
					$.getJSON(url, function () {
						incrustar("unhidable");//XMB20220601 Abans de v1.3.15 es passava com argument viz.attr("data-state"): ara ja no tenim en compte state: sempre unhidable 
					});
				}
			}
		}
	} //dataviz()