/*jshint esversion: 6*/

import getNextAriaControlsId from "./getnextariacontrolsid.js";
import interruptor from "./interruptor.js";

//Invocació (és multiinvocable: en una pàgina pot haver més d'un botó)
//id requerit. Ha de ser id únic i apuntar a element que cal ocultar:
//IDESCAT.onoff({id: "Espanya", label: "Espanya"});
//En la configuració actual el botó s'insereix just abans d'aquest element.
//Però com és un objecte podria flexibilitxar-se en el futur en cas de ser necessari amb un segon id (where) on incrustar el botó {id: "id", where: ""}
export default function onoff(o){
	if(typeof o!=="object" || !o.id){
		return;
	}

	const 
		id=o.id,
		label=o.label || id, //Si no es passa label s'agafa identificador
		idfor=getNextAriaControlsId(),
		//html='<div class="onoff"><input id="'+idfor+'" type="checkbox" class="invisible" tabindex="-1" name="onoff" value="0" autocomplete="off" aria-controls="'+id+'"><label for="'+idfor+'"><span role="button" tabindex="0" aria-pressed="false">'+label+'</span><span class="interruptor"></span></label></div>',
		target=$("#"+id)
	;

	if(!target.length){
		return;
	}

	interruptor({
		target: target,
		isChecked: o.hasOwnProperty('checked') ? o.checked : false,
		id: idfor,
		label: label,
		dreta: o.hasOwnProperty('dreta') ? o.dreta : true
	});

	//$(html).insertBefore(target);
	
	const 
		input=$("#"+idfor),
		button=$("#"+idfor+"+label span[role=button]")
	;

	//IDESCAT.nooutline(button);

	input.change(function(){
		target.toggle();
		button.attr("aria-pressed", !(button.attr("aria-pressed")==="true"));
	});

	/*button.on("keyup", function(event){
		// AMG20240415 https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
		// Older browsers may return "Spacebar" instead of " " for the Space Bar key. Firefox did so until version 37.
		if((event.type==="keyup" && (event.key==="Enter" || event.key===" " || event.key==="Spacebar")) || event.type==="click"){
			input.trigger("click");
		}
		return false;
	});*/
}