/*jshint esversion: 6*/

import alertARIA from "./alertaria.js";
import nooutline from "./nooutline.js";

export default function arbre(){
	alertARIA();//inicialitzem l'element d'avisos per l'accessibilitat
	if($(".Arbre").length){
		var
			wl=window.location,
			host=wl.protocol+"//"+wl.hostname,

			iniArbre=function(item){
				var
					arbre=$(item),
					imgcarregant= new Image(31,5),
					elmcarrega = document.createElement("img"),
					mesA=arbre.find(".Mes a"),
					n=function(s){ //fa funcions de toggle intel.ligent
						$(s).click(function(e){
								if($(this).next("ul:visible").length){ //té nodes o estan ocults
								 	tancar(this); //tancar el node clicat
								 }else{
								 	obrir(this); //obrir el node clicat
								}
							return false;
						});
					},
					updateEstat=function(){
						var dp=$("body").data("data-plegable");
						if(typeof dp!=="undefined" && dp.length){
							var
								reg=/Plegable=(.[^\&]*)/,
								hash=wl.hash
							;
							return ((hash.match(reg)!==null) ?
								hash.replace(reg, "Plegable="+dp.join(","))
								:
								((hash==="") ? "#" : hash+"&")+"Plegable="+dp.join(",")
							);
						}
						return "";
					},
					obrir=function(s){	//no selecc (obre node) FLM20190801
						if($(s).parent(".ApartDCont li").hasClass("taula")){//és taula
							//si l'arbre té la clase "Plegable" i existeix al body un array d'estat dels plegables,
							//llavors s'ha d'afegir #Plegable=id1,id2.. al final de la uri.
							window.location.href=$(s).attr("href")+updateEstat();
							return;
						}

						sel(s);

						var
							liselecc=arbre.find(".ApartDCont li.selecc"),
							ul=liselecc.children("ul"),
							isulelement=null
						;

						//Accessibilitat
						liselecc.attr("aria-expanded", "true");

						if(ul.length){ //si ja s'ha desplegat mostrem
							$(ul).show();
						}else{
							var
                uri=$(s).attr("href"),
                selecc=arbre.find(".ApartDCont .selecc"),
                group, li, a
              ;

							$.get(uri+"&m=a", function(data){
								isulelement=(data && data.replace(/^\s+/,"").slice(0,3) === "<ul") ? true : false;
								// if(!/<ul/.test(data)){ //NOK
								if(!isulelement){//NOK
									window.location.href=uri;
								}else{ //OK
									selecc.append(data); //obre node
									//Accessibilitat
									group=selecc.find("ul li");
								  li=group.children("a").parent("li");
								  a=li.children("a");

									//Accessibilitat
									a.attr("tabindex", "-1");
									li
									  .attr("tabindex", "0")
									  .click(function(e){
									    return updatePlegar($(this).children("a"));
									  })
									  .keyup(function(e){
									    if(e.key==="Enter"){
									      return updatePlegar($(this).children("a"));
									    }
									  })
									;

									nooutline(li);
									nooutline(a);

									//Deshabilitem l'opció de poder realitzar click en un element 'li' que no tingui vincle('a')
									group.not("a").unbind("click");

									n(selecc.find("ul > li > a")); //assigna click nous nodes

									//actualitza uri de M i +/-  si no s'ha arribat a taula (inexist de node sense link)
									if (!arbre.find("> .ApartDCont > ul li.taula :not(a)").html()){
										aim(uri);
									}
								}
							 });
						}
					},
					tancar=function(s){ // tanca node selecc
						sel(s);
						var liselecc=arbre.find(".ApartDCont li.selecc");
						liselecc.children("ul").hide();

						//Accesibilitat
						liselecc.attr("aria-expanded", "false");

						//si no estem visualitzant taula actualitza uri index i metod
						//(si taula conve conservar no link i negreta de node que apunta a taula)
						if(!$("#Contingut table.ApartNum").length){
							aim($(s).attr("href"));
						}
					},
					//ai=function(data, arbre){
					ai=function(data){
						arbre.children(".ApartDCont").first().html(data);
						n(arbre.find(".ApartDCont li a")); //cal reassignar click a nou cont. visible i invertir logica si ndx desplegat
					},
					aim=function(uri){
						//actualitza link M
						//$("#Titol a").attr("href",uri+"&m=m");
						//actualitza +/- segons si m=i o no
						//Excepció ODS XMB20200722
						if($("#ods").length){
							$(mesA).attr("href", $(mesA).attr("href").replace("&m=i","") + ( ($(mesA).find("span.mesmenys").text()=="+") ? "&m=i" : "" ) );
						}else{
							$(mesA).attr("href", uri + ( ($(mesA).find("span.mesmenys").text()=="+") ? "&m=i" : "" ) );
						}
					},
					sel=function(a){//a = <a href>
						arbre.find(".ApartDCont li.selecc").removeClass("selecc"); //evitem que dos selecc
						$(a).parent(".ApartDCont li").addClass("selecc");
					},
					//Filtrar per àmbit geogràfic
					iniGeo=function(){
						//comprobar si en efecte desapareixen nodes (filtrat) i si hi ha algun botó de filtre geo
						var geo=arbre.find(".info .geo");
						if( geo.length ){

							if( geo.is("a") ){
								//Afegir estat de plegat quan és enllaç
								geo.click(function(ev){
									ev.preventDefault();
									window.location.href=geo.attr("href")+updateEstat();
								});
							}

							if( arbre.hasClass("filtrat") ){
								var
									boto=function(t){
										var
											title={"show": IDESCAT.msg.mostrarNoDisp+": "+t, "hide": IDESCAT.msg.ocultarNoDisp+": "+t},
											button=' <button class="tot" title="'+title.show+'" aria-label="'+title.show+'">'+IDESCAT.msg.mostrarTot+'</button>'
										;

										geo.after(button);
										//No hi ha text a .geo pq és Catalunya
										if(t==="Catalunya"){
											geo.remove();
										}

										return title;
									},
									geotext=( geo.text() ) ? geo.text() : "Catalunya",
									title=boto(geotext)
								;

								arbre.find(".tot").click(function(){
									var
										//nd=arbre.find("li.nd"),
										btnTot=arbre.find("button.tot"),
										btnTotId=$(btnTot).attr("aria-controls")
									;
									if($(this).text()===IDESCAT.msg.mostrarTot){
										$(this).text(IDESCAT.msg.noMostrarTot);
										arbre.addClass("visible"); //res a veure amb el visible dels nodes
										$(btnTot).attr("title", title.hide);
										$(btnTot).attr("aria-label", title.hide);
										$("#"+btnTotId).text(IDESCAT.msg.noMostrarTot);
									}else{
										$(this).text(IDESCAT.msg.mostrarTot);
										arbre.removeClass("visible");
										$(btnTot).attr("title", title.show);
										$(btnTot).attr("aria-label", title.show);
										$("#"+btnTotId).text(IDESCAT.msg.mostrarTot);
									}
								});
							}
						}
					},
					updatePlegar=function(a){
						if(a.next("ul:visible").length){ //té nodes o estan ocults
							tancar(a); //tancar el node clicat
						}else{
							obrir(a); //obrir el node clicat
						}

						return false;
					},
					iniAriaArbre=function(){
						var group = arbre.find("div.ApartDCont ul li");
						group.children("a").each(function(){//FLM20190801
							var
								a = $(this),
								li = a.parent("li")
							;
							nooutline(li);
							nooutline(a);
							li
								.click(function(e){
									return updatePlegar(a);
								})
								.keyup(function(e){
									if(e.key==="Enter"){
										return updatePlegar(a);
									}
								})
								.attr("tabindex", "0")
							;
							a.attr("tabindex", "-1");

							//Accessibilitat
							if(!li.hasClass("taula")){
								li
									.attr("aria-expanded", false)
									.attr("tabindex","0")
								;
								a.attr("tabindex", "-1");
							}

							if(li.children("ul[role=group]").length){
								li.attr("aria-expanded",  li.children("ul[role=group]").css("display") !== "none");
							}
						});
						//Deshabilitem l'opció de poder realitzar click en un element 'li' que no tingui vincle('a')
						group.not("a").unbind("click");
					}
				;

				mesA
					.html('<span class="mesmenys max">+</span><span class="WAIocult"> '+IDESCAT.msg.arbre.mes+'</span>')
				;

				iniAriaArbre();

				imgcarregant.src="/images/carrega.gif";
				elmcarrega.setAttribute("alt",IDESCAT.msg.carregant);
				elmcarrega.setAttribute("src","/images/carrega.gif");
				elmcarrega.className = "carrega";
				$(elmcarrega).css({display:"none", marginLeft:"8px"});
				arbre.find(".accio").append($(elmcarrega));
				n(arbre.find(".ApartDCont ul li > a"));
				iniGeo();

				//per simplicitat no guardem index desplegat pero aquest te info de caducitat
				$(mesA).click(function(evt){
					evt.stopPropagation();

					var //FLM20190724
						clicat=$(this).data("clicat") || false,
						href=$(this).attr("href").replace(/\&m=i/, ""),
						nnod
					;

					if(!clicat){  //+
						nnod=(href+"&").replace(/\&n=(.[^\&]*)\&/, "&").replace(/\&$/, "");
						arbre.find(".accio .carrega").show();
						//cerca sense node per ficar a la cache
						$.get(nnod+"&m=ia",	function(data){
							arbre.find(".accio img.carrega").hide();
							ai(data);
							$(mesA).attr("href", href);
							$(mesA).children("span.mesmenys").removeClass("max").addClass("min").text("−");

							//seleccionem i eliminem link si es taula
							if(!arbre.find("ul li a[href='"+href+"']").parent("li").addClass("selecc").length) {
								/*IE*/ arbre.find("ul li a[href='"+host+href+
								"']").parent("li").addClass("selecc");
							}
							arbre.find("ul li.selecc.taula a").
								replaceWith(document.createTextNode(arbre.find("ul li.selecc.taula a").text()));

							//Accessibilitat
							$(mesA).children("span.WAIocult").text(" "+IDESCAT.msg.arbre.menys);
							iniAriaArbre();
							alertARIA(IDESCAT.msg.arbre.desplegat);
						});
					}else{	//-
						$.get(href+"&m=ai", function(data){
							ai(data);
							$(mesA).attr("href", href+"&m=i");
							$(mesA).children("span.mesmenys").removeClass("min").addClass("max").text("+");

							//Accessibilitat
							$(mesA).children("span.WAIocult").text(" "+IDESCAT.msg.arbre.mes);
							iniAriaArbre();
							alertARIA(IDESCAT.msg.arbre.plegat);
						});
					}

					$(this).data("clicat",!clicat);
					return false;
				})
				.on("keyup",
					function(ev){
						if(ev.key==="Enter"){
							ev.stopPropagation();
						}
					}
				)
				;

			} //fi iniArbre()
		;
		//ini. tots els arbres
		$(".Arbre").each(function(){
			iniArbre(this);
		});
	}
}//arbre()
