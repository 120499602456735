/*jshint esversion: 6*/

//1.0.29 autoincrementador
var getNum=(function () {
  var n=0;
  return function () {
    n++;
    return n;
  };
})();

export default function getNextAriaControlsId(){
  return "ARIAcontrols"+getNum();
}
