/*jshint esversion: 6*/

import getnextariacontrolsid from "./getnextariacontrolsid.js";
import enriqueixTaules from "./enriqueixTaules.js";

export default function codlit(checks){
	if( $("table.ApartNum thead th.cod").length && $("table.ApartNum thead th.lit").length){
		var
			/*S'encarrega de mostrar/ocultar una columna cod o lit*/
			showCol=function(th, show){
				var
					clsop={lit:"cod", cod:"lit"},
					th2=$(th).parent().find("th."+clsop[$(th).attr("class")])
				;
				if(show){//mostrar columna
					$(th).css("display", "table-cell");
					$(th2).attr("colspan", 1);
				}else{ //amagar columna
					$(th).css("display", "none");
					$(th2).attr("colspan", 2);
				}
			}
		;

		$("table.ApartNum").each(function(i){
			var
				thcodlit=$(this).find("thead tr:last th.cod, thead tr:last th.lit"),
				check=(typeof checks==="undefined") ? [false,true] : checks[i],
				idAriaControlsCol1=[],
				idAriaControlsCol2=[]
			;
			$(this).find("tbody").find("th").each(function(index){
				var idAriaControl='';
				if ($(this).attr("id")) {
					idAriaControl = $(this).attr("id");
				} else {
					idAriaControl = getnextariacontrolsid();
					$(this).attr("id", idAriaControl);
				}
				if(index%2){
					idAriaControlsCol1.push(idAriaControl);
				}else{
					idAriaControlsCol2.push(idAriaControl);
				}
			});
			//comprovem que es tracta d'una taula ApartNum amb codi/nom que es pot amagar:
			if($(thcodlit[0]).hasClass("cod") && $(thcodlit[1]).hasClass("lit") && (check[0] || check[1])){
				var
					html="",
					chk=[((check[0])?'checked="checked"':'""'), ((check[1])?'checked="checked"':'""')],
					rowspan=$(thcodlit).attr('rowspan') ? " rowspan='"+$(thcodlit).attr('rowspan')+"'" : ""
				;
				$(this).data("cod",check[0]).data("lit",check[1]);
				thcodlit.each(function(j){
					html+='<label><input type="checkbox" name="codiNom" '+ ' aria-expanded="'+check[j]+'" aria-controls="'+(j===0 ? idAriaControlsCol2.join(" ") : idAriaControlsCol1.join(" "))+'"'+
						chk[j] + ' value="' + j + '"/>' + $(this).html() + '</label>';
				});

				$(thcodlit).remove();
				$(this).find('thead tr.cap').prepend(
					'<th colspan="2"'+rowspan +' scope="colgroup" class="etieti">'+html+'</th>'
				);

				//estat inicial de les columnes cod/lit
				if(!check[0]){
					showCol($(this).find("tbody tr th.cod"), false);
				}else if(!check[1]){
					showCol($(this).find("tbody tr th.lit"), false);
				}

				//click checkbox codi/nom
				$(this).find("thead tr th input").click(function(){
					var
						clases=["cod","lit"],
						//valor=parseInt($(this).attr("value")),
						valor=parseInt($(this).val(), 10),
						clas=clases[valor],
						taula=$(this).closest("table"),
						nouestat=!(($(taula).data(clas)!==null) ? $(taula).data(clas) : true),
						th=$(taula).find("tbody tr th."+clas),
						expanded=$(this).attr("aria-expanded") === "true"
					;

					$(taula).data(clas, nouestat);
					//Com que no volem els dos checks desactivats, en aquest cas s'activa el check oposat.
					if($(taula).data(clases[0])===false && $(taula).data(clases[1])===false){
						showCol(th, false);
						//fem click al check oposat
						$(taula).find('thead tr th input[value="'+((valor+1)%2)+'"]').click();
					}else{
						if(nouestat){ //mostrar columna
							showCol(th, true);
						}else{ //amagar columna
							showCol(th, false);
						}
					}
					$(this).attr("aria-expanded", !expanded);
				});
			}
		});
		enriqueixTaules();
	}
}//codlit()
