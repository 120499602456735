/*jshint esversion: 6*/

export default function tooltip(str){
	var elems = $(str);
	if(elems.length > 0){
		elems.tooltip();
		elems.each(function(){
			$(this).click(function(ev){
				ev.stopPropagation();
				$(this).tooltip("open");
			});
		});
	}
}
