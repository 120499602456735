/*jshint esversion: 6*/

import agrupaclassif from "./agrupaclassif.js";
import nooutline from "./nooutline.js";
import getnextariacontrolsid from "./getnextariacontrolsid.js";

//No es contempla l'escenari que tinguem més d'una taula del tipus 'agrupa' a la mateixa pàgina
//Tenim dos tipus d'estructura per agrupar: 1) https://www.idescat.cat/noms/?q=joan 2) https://www.idescat.cat/indicadors/?id=anuals&n=13259
export default function agrupa(taulaAgrupableConf){
	if($("table.ApartNum tbody tr.titol").length || $("table.Classif.agrupa").length){//era $("table.Classif tbody tr[data-id]")
		var
			//taula Interactiva
			agrupaApartNum=function(){
				var
					imgmenys={
						src:"/images/bmenys.png",
						alt:"[-]",
						title:IDESCAT.msg.plegarTbody,className:"Mes"
					},
					imgmes={
						src:"/images/bmes.png",
						alt:"[+]",
						title:IDESCAT.msg.estendreTbody,className:"Mes"
					},
					ImgEstendre=function(img){
						var imatge=document.createElement("img");
						imatge.className=img.className;
						imatge.setAttribute("src",img.src);
						imatge.setAttribute("title",img.title);
						imatge.setAttribute("alt",img.alt);
						return imatge;
					},
					callbackfn=null,
					ini=function(callback){

						if(typeof callback!=="undefined"){callbackfn=callback;}

						// Inici del procés
						//*****************
						$("table.ApartNum").each(function(iTaula){

							if($(this).find("tr.titol").length && $(this).find("tbody").length){
								fixarCapcaleres($(this));
								posarIconaTaula($(this),iTaula);
								posarIconesTbody($(this),iTaula);

								//Paràmetre smart present
								if(getConfTaula(iTaula, "smart")===true){
									//Tots els grups amb zero resultats oberts i sense icona.
									//El primer grup amb resultas es desplegarà.
									//Els paràmetres senseicona i plegat, prevalen a l'smart.

									var algunObert=false;
									$(this).find("tbody:not(.ajuda)").each(function(iTbody){
										if($(this).find("tr.titol").text().indexOf("(0)")>-1){
											//Amb zero resultats, s'obren i s'elimina la possiblitat de tancar-lo.
											estendreTbody($(this).find("tr.titol th:first"));
											anulaFuncionalitat($(this).find("tr.titol th:first"));
										}else{
											if(getConfTbody(iTaula,iTbody,"senseIcona") === true){
												estendreTbody($(this).find("tr.titol th:first"));
												anulaFuncionalitat($(this).find("tr.titol th:first"));
												algunObert=true;
											}else if(getConfTbody(iTaula,iTbody,"plegat") === true){
												plegarTbody($(this).find("tr.titol th:first"));
											}else{
												if(!algunObert){
													estendreTbody($(this).find("tr.titol th:first"));
													algunObert=true;
												}else{
													plegarTbody($(this).find("tr.titol th:first"));
												}
											}
										}
									});
								}
							}
						});
						//Vinculació dels clicks a les imatges
						$("table.ApartNum tbody tr.titol th.agrupa").unbind("click").click(function(){
							plegarTbody($(this));
						});
						$("table.ApartNum tbody tr.titol th.desagrupa").unbind("click").click(function(){
							estendreTbody($(this));
						});
						$("table.ApartNum thead th.agrupa").click(function(){plegarTot($(this));});
						$("table.ApartNum thead th.desagrupa").click(function(){desplegarTot($(this));});
						if (callbackfn!==null){callbackfn();}
					},
					getConfTaula=function(iTaula,param){
						if (typeof taulaAgrupableConf!=="undefined" &&
							typeof taulaAgrupableConf[iTaula]!=="undefined" &&
							taulaAgrupableConf[iTaula][param]){
								return taulaAgrupableConf[iTaula][param];
						}
						return null;
					},
					getConfTbody=function(iTaula,iTbody,param){
						//Si tenim dades de la configuració del tbody
						if(typeof taulaAgrupableConf!=="undefined" &&
							typeof taulaAgrupableConf[iTaula]!=="undefined" &&
							typeof taulaAgrupableConf[iTaula].tbody!=="undefined"){
								if(typeof taulaAgrupableConf[iTaula].tbody[iTbody]!=="undefined" &&
									typeof taulaAgrupableConf[iTaula].tbody[iTbody][param]!=="undefined"){
										return taulaAgrupableConf[iTaula].tbody[iTbody][param];
								}
						}
						return null;
					},
					posarIconaTaula=function(taula,i){
						/* A partir del vector iconaTaula es determina quines taules tindran la icona de plegar/encongir i a més si serà un "-" o un "+".
							La funció rep la taula analitzada i l'index dins del total de les taules. */
						if(typeof taulaAgrupableConf!=="undefined"){
							if(typeof taulaAgrupableConf[i]!==null &&
								typeof taulaAgrupableConf[i]!=="undefined"){
								if(taulaAgrupableConf[i].icona!==""){
									if(taulaAgrupableConf[i].icona==="+"){
										taula.find("thead th.etieti").addClass("desagrupa").prepend(new ImgEstendre(imgmes));
									}
									if(taulaAgrupableConf[i].icona==="-" ||
										typeof taulaAgrupableConf[i].icona==="undefined"){
										taula.find("thead th.etieti").addClass("agrupa").prepend(new ImgEstendre(imgmenys));
									}
								}
							}else{
								taula.find("thead th.etieti").addClass("agrupa").prepend(new ImgEstendre(imgmenys));
							}
						}else{
							taula.find("thead th.etieti").addClass("agrupa").prepend(new ImgEstendre(imgmenys));
						}
					},
					posarIconesTbody=function(oTaula,iTaula){
						var totsPlegats=getConfTaula(iTaula,"totsPlegats");
						oTaula.find("tr.titol").each(function(i){
							var
								tbodyPlegat=getConfTbody(iTaula,i,"plegat"),
								tbodySenseIcona=getConfTbody(iTaula,i,"senseIcona"),
								thfirst=$(this).children("th:first")
							;

							if(totsPlegats){
								if(tbodyPlegat===false){
									thfirst.addClass("agrupa").prepend(new ImgEstendre(imgmenys))
									;
									$(this).children("th").attr({"title":IDESCAT.msg.plegarTbody});
								}else{
									thfirst.addClass("desagrupa").prepend(new ImgEstendre(imgmes));
									//PDM 170619 Cambiat hide per addclass("ocult"), era incosistent amb estendre, i donava errors en casos com la cerca de poblacio
									$(this).siblings("tr").addClass("ocult");
									$(this).children("th").attr({"title":IDESCAT.msg.estendreTbody});
								}
							}else{
								if(tbodyPlegat===true){
									thfirst.addClass("desagrupa").prepend(new ImgEstendre(imgmes));
									//PDM 170619 Cambiat hide per addclass("ocult"), era incosistent amb estendre, i donava errors en casos com la cerca de poblacio
									$(this).siblings("tr").addClass("ocult");
								}else{
									thfirst.addClass("agrupa").prepend(new ImgEstendre(imgmenys));
								}
							}

							if(thfirst.siblings("td").length //https://www.idescat.cat/noms/?q=joan
							 	|| thfirst.hasClass("agrupa") || thfirst.hasClass("desagrupa") //https://www.idescat.cat/indicadors/?id=anuals&n=13259
							){//NOTE: a11y
								thfirst.attr({
									"role" : "button",
									"tabindex" : "0",
									"aria-expanded" : thfirst.hasClass("agrupa") ? true : false
								});
								if(!thfirst.siblings("td").length){//tipus: https://www.idescat.cat/indicadors/?id=anuals&n=13259
									var idAriaControls = [];
									$(this).siblings("tr").each(function(){
										var idAriaControl = getnextariacontrolsid();
										idAriaControls.push(idAriaControl);
										$(this).attr("id", idAriaControl);
									});
									thfirst.attr("aria-controls", idAriaControls.join(" "));
								}
								nooutline(thfirst);
							}

							if(tbodySenseIcona){
								//estendreTbody($(this).children("tbody th:first"));
								anulaFuncionalitat($(this).children("tbody th:first"));
							}
						});
					},
					fixarCapcaleres=function(taula){
						/* Millora l'aspecte de les capçaleres quan tot està plegat, fixant la mida d'inici. Així, quan tot es plega, l'aspecte dels
						titols de les capçaleres no s'encongeix del tot
						@param taula Taula que analitzem.*/
						var ampladaMax=0;
						$(taula).find("tbody tr th[scope=row]").each(function(){
							if(parseInt($(taula).css("width"), 10)>ampladaMax){
								ampladaMax=parseInt($(taula).css("width"), 10);
							}
						});
						$(taula).find("th.etieti:first").css("width",ampladaMax);
					},
					plegarTbody=function(th){
						/* La funció rep una imatge i no el tbody directament, pq qui llença l'acció de plegar/desplegar és el click sobre la imatge */
						th.unbind("click").removeClass("agrupa").addClass("desagrupa");

						//Si hi ha coincidencies amb la paraula cercada
						if(th.siblings("td").length || th.hasClass("agrupa") || th.hasClass("desagrupa")){//NOTE: a11y
							th.attr("aria-expanded" , false);
						}

						$(th.find("img").get(0)).attr({
							"src":imgmes.src,
							"title":IDESCAT.msg.estendreTbody,"alt":"[+]"
						});
						th.attr({"title":IDESCAT.msg.estendreTbody});
						th.on("click keyup", function(event){
							if((event.type==="keyup" && event.key==="Enter") || event.type==="click"){
								estendreTbody(th);
							}
						});

						/* Quan el grup de tr que estiqui plegant sigui el darrer, l'estil del tr pare ha de canviar per
						dibuixar el filet blau continu*/
						var pare=$(th.parents("tr").get(0));
						if(pare.siblings("tr:last").hasClass("darrera")){
							pare.addClass("darrera");
						}
						pare.siblings("tr").addClass("ocult");
						avaluaPlegats(th.parents("table.ApartNum"));
					},
					estendreTbody=function(th){
						th.unbind("click").removeClass("desagrupa").addClass("agrupa");
						//Si hi ha coincidencies amb la paraula cercada
						if(th.siblings("td").length|| th.hasClass("agrupa") || th.hasClass("desagrupa")){//NOTE: a11y
							th.attr("aria-expanded" , true);
						}
						$(th.find("img").get(0)).attr({
							"src":imgmenys.src,
							"title":IDESCAT.msg.plegarTbody,
							"alt":"[-]"
						});
						th.on("click keyup", function(event){
							if((event.type==="keyup" && event.key==="Enter") || event.type==="click"){
								plegarTbody(th);
							}
						});
						th.attr({"title":IDESCAT.msg.plegarTbody});

						/* Quan el grup de tr que estiqui plegant sigui el darrer, l'estil del tr pare ha de canviar per
						dibuixar el filet blau discontinu*/
						var pare=$(th.parents("tr").get(0));
						if(pare.siblings("tr:last").hasClass("darrera")){
							pare.removeClass("darrera");
						}
						//pare.siblings("tr").show();
						pare.siblings("tr").removeClass("ocult");
						avaluaPlegats(th.parents("table.ApartNum"));
					},
					avaluaPlegats=function(taula){
						var vincle;
						//Quan tots els tbody d'una taula estiguin plegats o estesos, l'icona de l'arrel ha d'estar en consonància.
						if($(taula).find("tbody tr.titol th.agrupa").length===0){
							vincle=$(taula).find("thead th.etieti.agrupa");
							vincle.unbind("click");
							vincle.removeClass("agrupa").addClass("desagrupa").click(function(){
								desplegarTot($(this));
							});
							$(vincle.find("img").get(0)).attr({
								"src":imgmes.src,
								"alt":imgmes.alt,
								"title":imgmes.title
							});
						}
						if($(taula).find("tbody tr.titol th.desagrupa").length===0){
							vincle=$(taula).find("thead th.etieti.desagrupa");
							vincle.unbind("click");
							vincle.removeClass("desagrupa").addClass("agrupa").click(function(){
								plegarTot($(this));
							});
							$(vincle.find("img").get(0)).attr({
								"src":imgmenys.src,
								"alt":imgmenys.alt,
								"title":imgmenys.title
							});
						}
					},
					plegarTot=function(th){
						th.parents("table.ApartNum:first").find("tbody tr.titol th.agrupa").each(function(){
							plegarTbody($(this));
						});
					},
					desplegarTot=function(th){
						th.parents("table.ApartNum:first").find("tbody tr.titol th.desagrupa").each(function(){
							estendreTbody($(this));
						});
					},
					anulaFuncionalitat=function(th){
						th.unbind("click keyup");
						th.removeClass("agrupa").removeClass("desagrupa");
						th.find("img.Mes, img.Menys").remove();
						th.removeAttr("title");
						th.siblings("td").removeAttr("title");
					}
				;

				//if(typeof(inhibidorAgrupa)==="undefined"){
					ini();
				//}
			}, //fi agrupaApartNum()
			classif=function(){
				//Si link mana el link sobre l'esdeveniment del tr (plesgar/desplegar)
				$("table.Classif a").click(function(e){
					e.stopPropagation();
				});

				//inicialitzar events click a les taules agrupables
				$("table.Classif").each(function(){
					if($(this).find("tbody tr[data-id]").length){
						agrupaclassif(this);
					}
				});
			}
		;

		if($("table.Classif.agrupa").length){
			classif();
		}else{
			agrupaApartNum();
		}
	}
}//agrupa()
