/*jshint esversion: 6*/

export default function normus(s) {
	var 
		artinv="", 
		nu="", 
		art="",
		regex = "",// s.match(/(.+)( \(.+\))$/),
		net=s
		//par=""
	;

	/*if(regex){
		net=regex[1];
		par=regex[2];
	}

	if(!par.length){
		net=s;
	}*/

	regex = net.match(/(.+)((, el)|(, Eth)|(, Er)|(, la)|(, Era)|(, sa)|(, els)|(, los)|(, les)|(, Es)|(, l\')|(, s\'))$/);

	if(regex){
		artinv=regex[2];
		nu=regex[1];
	}else{
		nu=net;
	}

	if(artinv.length){
		art=artinv.replace(/^, /, '')+" ";
		art=art.replace(/^(l|s)\' /, '$1\'');
	}
	return art+nu;//+par
}