/*jshint esversion: 6*/

export default function ajuda(info){
	if($("table.ApartNum").length){
		var
			mostrarInfo=function(e){
				var vincle=$(e.currentTarget);
				if(infoActiva!==null) {
					amagarInfo(infoActiva,e);
				}
				e.preventDefault();
				vincle.parents("th:first").addClass("ajuda");
				vincle.text("x").attr("title",IDESCAT.msg.tancar);
				$(vincle).closest("table").find("tbody.ajuda").removeClass("ocult");
				$(vincle).closest("table").find("tbody.ajuda td:first").html(info[vincle.parents("th.ajuda").attr("id")]);
				infoActiva=vincle;
				vincle.unbind("click").click(function(e){amagarInfo(vincle,e);});
				$(document).bind("click",function(e){
					var
						amagar=true,
						obj=$(e.target)
					;
					if(obj.parents(".ajuda").length){amagar=false;}
					if(obj.hasClass("ajuda")){amagar=false;}
					if (amagar && infoActiva!==null){
							amagarInfo(infoActiva,e);
					}
				});
			},
			amagarInfo=function(vincle,e){
				$(document).unbind("click");
				if($(e.target).parent("span").hasClass("ajuda")){
					e.preventDefault();
				}
				vincle.parents("th.ajuda").removeClass("ajuda");
				vincle.text("?").attr("title",IDESCAT.msg.queesaixo);
				$(vincle).closest("table").find("tbody.ajuda").addClass("ocult");
				vincle.unbind("click").click(mostrarInfo);
			},
			infoActiva=null //emmagatzemarà el th que està mostrant la info.
		;
		if(typeof info!=="undefined"){
			for(var i in info){
				var
					codi='<tbody class="ajuda ocult"><tr><td colspan="' +
					$("#"+i).parent("th").parent("tr").children().length + '"></td></tr></tbody>',
						textSpan=$("#"+i).html()
				;
				$($("#"+i).parents("table").get(0)).find("thead").after(codi); //Enganxo el tbody d'informació (FLM20180628 Abans deixa "tfoot" en comptes de "thead")
				$("#"+i).parent("th").attr("id",i);
				$("#"+i).html(textSpan);
				$("#"+i).append('<span class="ajuda">&nbsp;(<a href="" title="' +
					IDESCAT.msg.queesaixo +'">?</a>)</span>'); //Creació de la 'x'.
				$("#"+i).find("span.ajuda a:first").click(mostrarInfo); //Activació de la funcionalitat
			}
		}
	}
}//ajuda()
