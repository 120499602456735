/*jshint esversion: 6*/

import getNextAriaControlsId from "./getnextariacontrolsid.js";
import nooutline from "./nooutline.js";

//cridada per agrupa() i sortejable()
export default function agrupaclassif(taula){
	var
		ambid=$(taula).find("tbody tr[data-id]"),
		trhead=$(ambid).closest(".Classif").find("thead tr"),
		plegats=$(taula).find("tbody .plegat"),
		toggle=function(e, s){
			if(s==="plegat"){
				e.removeClass("desplegat").addClass("plegat");
			}else{
				e.removeClass("plegat").addClass("desplegat");
			}
			if(e.data("id")){
				e.attr("aria-expanded", s==="plegat" ? "false" : "true");
			}
		},
		darrerTrs=$(taula).find("tbody tr").not("[data-id]"),
		toggleAll=function(ele){
			var
				trtbody=$(taula).find("tbody tr[data-id]"),
				pleg=$(taula).find("tbody tr[data-parent]"),
				desc=ele.children("th").children("a:first").text().trim()+": "
			;
			if( ele.hasClass("desplegat")){
				pleg.hide();
				toggle(trtbody, "plegat");
				toggle(ele, "plegat");
				ele.attr("aria-label", desc+IDESCAT.msg.classif.desplegar);
			}else{
				$(taula).find("tbody tr").show();
				toggle(trtbody, "desplegat");
				toggle(ele, "desplegat");
				ele.attr("aria-label", desc+IDESCAT.msg.classif.plegar);
			}
		},
		trEvent=function(element){
			var
				id=element.attr("data-id"),
				child=$(taula).find("tr[data-parent='" + id +"']"),
				anc=$(taula).find( "tr[data-ancestors~='" + id +"']")
			;
			if(element.hasClass("plegat")){
				child.show();
				toggle(element, "desplegat");
			}else{
				child.hide();
				anc.hide();
				toggle(anc, "plegat");
				toggle(element, "plegat");
				toggle(child, "plegat");
			}
			darrerTrs.removeClass("plegat");
		}
	;
	//Control·lem els que són desplegables (nodarrer): link i inicialitzem com plegats
	ambid
		.addClass("link")
		.addClass("plegat")
		.attr({
				role : "button",
				"aria-expanded" : "false",
				tabindex : 0
		})
		.on("click keyup", function(event){
			if((event.type==="keyup" && event.key==="Enter") || event.type==="click"){
				trEvent($(this));
			}
		})
	;
	nooutline(ambid);
	//Deduim darrer nivell perquè no tenen id: li esborrem el "plegat" que tenien de fàbrica
	$(trhead).addClass("plegat");
	plegats.hide();
	darrerTrs.removeClass("plegat");
	$(taula).find("tbody tr").not("[data-parent]").show();
	$(taula).removeClass("agrupa");

	//Taula desplegable. Click a la 1a fila (plegar/desplegar tots)
	trhead
		.click(function(){
			toggleAll($(this));
		})
		.keyup(function(evt){
			if(evt.key==="Enter"){
				toggleAll($(this));
			}
		})
	;

	/*** Accessibilitat ***/
	var ariaControlsIdRows=[],//id's aria-controls de l'acció deplegar tota la taula, contindrà els primers fills de cada pare.
	 ariaMap = {};

	 $(taula).find("tbody tr").each(function(){
		 	var tr = $(this),
				ariaId
			;
			if(typeof tr.data("id") !== "undefined"){
					ariaId="ARIA"+tr.data("id");
					ariaMap[ariaId] = [];
					if(tr.data("parent")){
						if(!tr.data("ancestors")){
							ariaControlsIdRows.push(ariaId);
						}
						ariaMap["ARIA"+tr.data("parent")].push(ariaId);
					}
			}else{
				ariaId = getNextAriaControlsId();
				ariaMap["ARIA"+tr.data("parent")].push(ariaId);
			}
			tr.attr("id",ariaId);
	 });
	 for (var id in ariaMap) {
		 $("#"+id).attr("aria-controls", ariaMap[id].join(" "));
	 }

	trhead
		.attr({
			role : "button",
			tabindex: "0",
			"aria-label": trhead.children("th").children("a:first").text().trim()+": "+IDESCAT.msg.classif.desplegar,
			"aria-controls": ariaControlsIdRows.join(" ")
		})
	;
	nooutline(trhead);
	/********************/
}//agrupaclassif()
