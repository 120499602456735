/*jshint esversion: 6*/

import analitica from "./analitica";

//Es deia WTmime
function GAmime(uri){
  if(uri.indexOf("f=csv")>-1 || uri.indexOf("f=ssv")>-1 ||
    uri.match(/\.csv$/) || uri.indexOf(".csv?")>-1){ /*XMB20201111 per a les consultes més freqüents i altres possibles CSVs*/
    return "CSV";
  }
  if(uri.indexOf("f=json")>-1){
    return "JSON";
  }
  if(uri.match(/\.pdf$/) || uri.indexOf(".pdf?")>-1 || uri.indexOf("PDF=TRUE")>-1 ||
    //XMB20181108: Premsa Ho incorporem quan canviem pàgina Premsa per adaptar-la a noves crides a govern.cat (en comptes de premsa.gencat.cat)
    (uri.match(/^serveis\/premsa\//) && uri.indexOf("fitxer=1")>-1)
  ){
    return "PDF";
  }
  if(uri.match(/\.doc(x?|(x?\?(\S+)?))$/) ||
    uri.indexOf(".odt") > -1 ){//FLM20210210 afegim format 'odt'
    return "DOC";
  }
  if(uri.match(/\.ppt(x?|(x?\?(\S+)?))$/)){
    return "PPT";
  }
  if(/*XMB20180504 actualitzat*/
    uri.match(/\.xlsx?(x?|(x?\?(\S+)?))$/) ||
    uri.indexOf(".ods") > -1 ||//FLM20210210 afegim format 'ods'
    (uri.match(/^(pub|sexterior|emex)\//)) && uri.indexOf("f=xls")>-1 /*pub per visors (aec) */ ||
    //XMB20181108 (industria|serveis|treball) canviat per (industria|eas|treball)
    (uri.match(/^(industria|eas|treball)\//) && uri.indexOf("ex=1")>-1) /* industria per ei, eip és estàndard */||
    (uri.match(/^classif\//i) && (uri.indexOf("TC=50")>-1 || uri.indexOf("EXC=1")>-1))
  ){
    return "XLS";
  }
  if(
    /*XMB20201111 ODS en realitat ODS s'autdeclara*/
    uri.indexOf("f=zip")>-1 ||
    /*XMB20180504*/
    uri.match(/\.zip$/) || uri.indexOf(".zip?")>-1
  ){
    return "ZIP";
  }
  return null;
}//GAmime()

function GAmimeBiblio(uri) {
  const
    URLU = new URL(uri),
    URLP = new URLSearchParams(URLU.search)
    ;
  return (URLU.href.toLowerCase().indexOf("export") > -1) && (URLP.has("format") || URLP.has("style")) ? "BIBLIO" : null;
}

//WTTipus() eliminada i amb ella getElementsByClassName()

/* S'ha eliminat codi WebTrends que incorporava i renombrat (abans es deia WTClic() i se li podia passar grup de contingut) */
export default function GAClic(ancora){
	var
    href = ancora.getAttribute("data-href"), //casos on el document que descarreguem és del tipus:'data:text/csv;...', i cal especificar la url que s'enregistrara. Exemple: https://www.idescat.cat/dades/ods/nu/1.2.1  
		strLocation = href ? href : ancora.href,
		domini=strLocation.slice(strLocation.indexOf("/")+2),
    //XMB20201123 Si link autodeclara mime-type s'agafa. P.e. ODS Eurostat
    mime=ancora.getAttribute("data-mime") //per major retro no fem ancora.dataset.mime
	;

	if(domini.indexOf("/")>-1){
		domini = domini.slice(0,domini.indexOf("/"));
	}

	var strURI = strLocation.replace("http://" + domini + "/", "").replace("https://" + domini + "/", "");

	if(domini == strURI){
		strURI = "";
	}

  //a partir d'aqui descarregues; abans p.e. (pendent) control clicks cap l'exterior (class=extern o domini)
  if (domini.indexOf("idescat") == -1 && domini.indexOf("biblio.idescat.cat") == -1) {
		return true;
	}//no comptabilitzar desc. externes (o serv. intern). A biblio no hi ha descàrregues (en realitat sí, però fora de control!)

  /* BIBLIO MIME*/
  if (domini.indexOf("biblio.idescat.cat") > -1) {
    mime = GAmimeBiblio(strLocation);
  }

  //XMB20201123 Si link autodeclara mime-type s'agafa. P.e. ODS Eurostat
  if (!mime){
    //Inferim mime-type d'href
    mime=GAmime(strURI);
  }

	if (mime){
    analitica({"name": "Download", "category": mime.toUpperCase(), "label": domini+"/"+strURI});
	}
	return true;
}//GAClic
